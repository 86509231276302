const translatedPaths = [
    ['/', '/es/'],
    ['/contact/', '/es/contactenos/'],
    ['/defi-trading-bot/', '/es/bot-de-trading-defi/'],
    ['/downloads/', '/es/descargas/'],
    ['/exchanges/', '/es/exchanges/'],
    ['/features/', '/es/caracteristicas/'],
    ['/features/custom-strategies/', '/es/caracteristicas/estrategias-personalizadas/'],
    ['/how-gunbot-works/', '/es/como-funciona-gunbot/'],
    ['/live-demo/', '/es/demo-en-vivo/'],
    ['/performance/', '/es/rendimiento/'],
    ['/performance/btcusdt/', '/es/rendimiento/btcusdt/'],
    ['/performance/ethusdt/', '/es/rendimiento/ethusdt/'],
    ['/performance/xrpusdt/', '/es/rendimiento/xrpusdt/'],
    ['/performance/solusdt/', '/es/rendimiento/solusdt/'],
    ['/performance/maticusdt/', '/es/rendimiento/maticusdt/'],
    ['/performance/chzusdt/', '/es/rendimiento/chzusdt/'],
    ['/performance/adausdt/', '/es/rendimiento/adausdt/'],
    ['/performance/bnbusdt/', '/es/rendimiento/bnbusdt/'],
    ['/performance/apeusdt/', '/es/rendimiento/apeusdt/'],
    ['/performance/shibusdt/', '/es/rendimiento/shibusdt/'],
    ['/performance/etcusdt/', '/es/rendimiento/etcusdt/'],
    ['/performance/atomusdt/', '/es/rendimiento/atomusdt/'],
    ['/performance/linkusdt/', '/es/rendimiento/linkusdt/'],
    ['/performance/dogeusdt/', '/es/rendimiento/dogeusdt/'],
    ['/performance/ltcusdt/', '/es/rendimiento/ltcusdt/'],
    ['/performance/trxusdt/', '/es/rendimiento/trxusdt/'],
    ['/performance/avaxusdt/', '/es/rendimiento/avaxusdt/'],
    ['/performance/uniusdt/', '/es/rendimiento/uniusdt/'],
    ['/performance/nearusdt/', '/es/rendimiento/nearusdt/'],
    ['/performance/luncusdt/', '/es/rendimiento/luncusdt/'],
    ['/performance/ethbtc/', '/es/rendimiento/ethbtc/'],
    ['/performance/xrpbtc/', '/es/rendimiento/xrpbtc/'],
    ['/performance/solbtc/', '/es/rendimiento/solbtc/'],
    ['/performance/mlnbtc/', '/es/rendimiento/mlnbtc/'],
    ['/performance/adabtc/', '/es/rendimiento/adabtc/'],
    ['/performance/bnbbtc/', '/es/rendimiento/bnbbtc/'],
    ['/performance/chzbtc/', '/es/rendimiento/chzbtc/'],
    ['/performance/maticbtc/', '/es/rendimiento/maticbtc/'],
    ['/performance/dotbtc/', '/es/rendimiento/dotbtc/'],
    ['/performance/apebtc/', '/es/rendimiento/apebtc/'],
    ['/performance/linkbtc/', '/es/rendimiento/linkbtc/'],
    ['/performance/ltcbtc/', '/es/rendimiento/ltcbtc/'], 
    ['/plans/upgrade/', '/es/planes/actualizaciones/'],
    ['/plans/', '/es/planes/'],
    ['/resellers/', '/es/vendedores/'],
    ['/use-cases/', '/es/formas-de-uso/']
]

export default translatedPaths