import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeadingH2 } from "components/misc/Headings.js";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import { ReactComponent as MessageIcon } from "feather-icons/dist/icons/message-square.svg";
import { ReactComponent as LayersIcon } from "feather-icons/dist/icons/layers.svg";
import { ReactComponent as ActivityIcon } from "feather-icons/dist/icons/activity.svg";
import { ReactComponent as ZapIcon } from "feather-icons/dist/icons/zap.svg";
import { ReactComponent as MonitorIcon } from "feather-icons/dist/icons/monitor.svg";

const Container = tw.div`relative -mt-16 mb-16`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const Heading = styled(SectionHeadingH2)`
  ${tw`w-full text-gray-900 mb-12 text-center font-bold text-3xl`}
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-4 flex mb-8`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 bg-white rounded-lg transition-all duration-300 ease-in-out`}
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #1a1a1a;
    background-image:
      radial-gradient(circle at 60% 70%, rgba(15, 35, 55, 0.95) 0%, rgba(25, 45, 65, 0.90) 50%, rgba(10, 30, 50, 0) 100%, transparent 100%),
      radial-gradient(circle at 50% 50%, rgba(20, 40, 60, 0.95) 0%, transparent 100%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const IconContainer = styled.div`
  ${tw`w-16 h-16 p-4 rounded-full flex items-center justify-center mb-6`}
  background-color: #E6FFFA;
  color: #319795;
`;

const TextContainer = tw.div`text-center`;

const Title = tw.h3`font-bold text-xl leading-snug text-gray-800 mb-3`;

const Description = tw.p`text-gray-600 text-sm leading-relaxed`;

export default ({ translations }) => {
  const cards = translations.features.cards.map(card => ({
    title: card.title,
    description: card.description
  }));

  const getIcon = (index) => {
    const icons = [AwardIcon, MessageIcon, LayersIcon, ActivityIcon, ZapIcon, MonitorIcon];
    return icons[index % icons.length];
  };

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{translations.features.heading}</Heading>
        {cards.map((card, i) => {
          const Icon = getIcon(i);
          return (
            <Column key={i}>
              <Card>
                <IconContainer>
                  <Icon />
                </IconContainer>
                <TextContainer>
                  <Title>{card.title}</Title>
                  <Description>{card.description}</Description>
                </TextContainer>
              </Card>
            </Column>
          );
        })}
      </ThreeColumnContainer>
    </Container>
  );
};