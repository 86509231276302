import React, { useEffect, useState, Suspense } from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/custom/home/homeHero.js";
import Why from "components/custom/home/WhyChooseGunbot.js";
import How from "components/custom/home/CtaHowDoesGunbotWork.js";
import FeaturesCTA from "components/custom/home/CtaFeatures.js";
import USPs from "components/custom/home/homeUSPs.js";
import New from "components/custom/home/New.js";
import Features from "components/custom/home/homeFeatures.js";
import Pricing from "components/custom/home/PricingPromo.js";
import GunbotAI from "components/custom/home/GunbotAI";
import RelatedTopics from "components/custom/RelatedTopics.js";
import Footer from "components/custom/Footer.js";
import { Helmet } from 'react-helmet';
import defaultContent from 'translations/home/en.js'

import Content from "_content/Content.js";

const isMobile = window.innerWidth <= 768;
const locationString = "/"


const ldJson2 = Content.structured.ldJson2
const ldJson3 = Content.structured.ldJson3
const ldJson4 = Content.structured.ldJson4
const ldJson11 = Content.structured.ldJson11

const Testimonials = React.lazy(() => import('components/custom/home/Testimonials.js'));



export default ({ language, translationsFolder }) => {

  const [translations, setTranslations] = useState(defaultContent);

  useEffect(() => {
    if (language !== 'en') {
      import(`translations/${translationsFolder}/${language}.js`)
        .then(module => setTranslations(module.default))
        .catch(error => console.error(`Failed to load translations: ${error}`));
    }
    else {
      setTranslations(defaultContent)
    }
  }, [language]);

  const locationStringTranslated = translations.pageInfo.locationString
  const descriptionTranslated = translations.pageInfo.description
  const titleTranslated = translations.pageInfo.title

  return (
    <>
      <Helmet>
        <title>{titleTranslated}</title>
        <meta name="description" content={descriptionTranslated} />
        <meta property="title" content={titleTranslated} />
        <link rel="canonical" href={'https://www.gunbot.com' + locationStringTranslated} />
        <meta property="og:locale" content={language + '_' + language.toUpperCase()} />
        <meta property="og:type" content="website" />
        <meta property="og:hashtag" content="#gunbot" />
        <meta property="og:title" content={titleTranslated} />
        <meta property="og:url" content={'https://www.gunbot.com' + locationStringTranslated} />
        <meta property="og:image" content="https://www.gunbot.com/gunbotcom-fb-preview.png" />
        <meta property="og:site_name" content="Gunbot.com" />
        <meta name="twitter:card" content="Gunbot" />
        <meta name="twitter:site" content="https://www.gunbot.com/" />
        <meta name="twitter:title" content="Gunbot. Community made crypto trading bot" />
        <meta name="twitter:description" content="Trade crypto like a boss." />
        <meta property="og:image" content="https://www.gunbot.com/gunbotcom-x-preview.png" />
        <meta property="og:description" content={descriptionTranslated} />
        <html lang={language} />
        <meta http-equiv="Content-Language" content={language} />
        <link rel="alternate" hreflang='es' href={'https://www.gunbot.com/es' + locationStringTranslated} />
        <link rel="alternate" hreflang="x-default" href={'https://www.gunbot.com' + locationString} />
        <script type="application/ld+json">
          {JSON.stringify(ldJson2)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(ldJson3)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(ldJson4)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(ldJson11)}
        </script>
      </Helmet>
      <AnimationRevealPage>
        <Hero translations={translations} />
        <USPs translations={translations} />
        <Suspense fallback={<div>Loading testimonials...</div>}>
          <Testimonials translations={translations} />
        </Suspense>
        <GunbotAI translations={translations} />
        <How translations={translations} />
        <New translations={translations} />
        <Pricing translations={translations} language={language} />
        <Why translations={translations} />
        <Features translations={translations} />
        {!isMobile && <FeaturesCTA translations={translations} />}
        {language === 'en' && <RelatedTopics
          title={translations.related.title}
          introText={translations.related.introText}
          articles={translations.related.articles}
        />}
        <Footer />
      </AnimationRevealPage>
    </>
  );
}
