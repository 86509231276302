import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link } from 'react-router-dom';
import { SectionHeadingH2, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/pnl-large.png";
import { ReactComponent as ServerIcon } from "feather-icons/dist/icons/server.svg";
import { ReactComponent as ZapIcon } from "feather-icons/dist/icons/zap.svg";
import { ReactComponent as TerminalIcon } from "feather-icons/dist/icons/terminal.svg";
import { ReactComponent as DownloadIcon } from "feather-icons/dist/icons/download.svg";
import { motion } from "framer-motion";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative md:mr-24 md:-ml-24`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const TextContent = tw.div`lg:py-8 text-left mb-8`;

const Subheading = tw(SubheadingBase)`text-left text-gray-800`;
const Heading = tw(
  SectionHeadingH2
)`mt-4 font-black text-left text-left leading-tight text-gray-800`;
const Description = tw.p`mt-8 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled(motion.div)`
  background: linear-gradient(135deg, #f0f2f5 0%, #e2e6ed 100%);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.18);

  &:hover {
    box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const FeatureIconContainer = styled.div`
  ${tw`inline-block text-primary-500 rounded-full p-2 mb-4 bg-teal-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const FeatureHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
`;

const CardDescription = styled.p`
  font-size: 0.875rem;
  color: #555;
  flex-grow: 1;
`;

const DisplayLink = tw(Link)`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-8 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline bg-primary-400 text-gray-100 shadow-lg hocus:bg-primary-500 hocus:text-gray-200`;

export default ({ translations }) => {
  const {
    subheading,
    heading,
    description,
    imageAlt,
    features,
    learnMoreLinkText,
    learnMoreLinkURL
  } = translations.usps;

  const imageSrc = TeamIllustrationSrc,
    imageRounded = true,
    imageBorder = false,
    imageShadow = true,
    textOnLeft = false,
    iconContainerCss = tw`bg-teal-300 text-teal-800`;

  const getIcon = (index) => {
    switch (index) {
      case 0:
        return <ServerIcon />;
      case 1:
        return <ZapIcon />;
      case 2:
        return <TerminalIcon />;
      default:
        return <DownloadIcon />;
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
            alt={imageAlt}
            width="640px"
            height="774px"
            loading="lazy"
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <CardGrid>
              {features.map((feature, index) => (
                <FeatureCard
                  key={index}
                  whileHover={{ y: -5, boxShadow: '0 10px 30px -15px rgba(0, 0, 0, 0.2)' }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <FeatureHeader>
                    <CardTitle>{feature.title}</CardTitle>
                  </FeatureHeader>
                  <CardDescription>{feature.description}</CardDescription>
                </FeatureCard>
              ))}
            </CardGrid>
          </TextContent>
          
          <DisplayLink to={learnMoreLinkURL} title="Learn more about Gunbot custom strategies">
            {learnMoreLinkText}
          </DisplayLink>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
