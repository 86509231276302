import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import tw, { styled } from 'twin.macro';



const demoCandles = [
  {
    "t": 1723766400000,
    "o": 1.574,
    "h": 1.579,
    "l": 1.564,
    "c": 1.579,
    "v": 119398.34999999999
  },
  {
    "t": 1723768200000,
    "o": 1.579,
    "h": 1.622,
    "l": 1.579,
    "c": 1.606,
    "v": 223156.01
  },
  {
    "t": 1723770000000,
    "o": 1.606,
    "h": 1.607,
    "l": 1.57,
    "c": 1.573,
    "v": 145648.09000000003
  },
  {
    "t": 1723771800000,
    "o": 1.573,
    "h": 1.583,
    "l": 1.561,
    "c": 1.582,
    "v": 116113.32999999999
  },
  {
    "t": 1723773600000,
    "o": 1.583,
    "h": 1.588,
    "l": 1.568,
    "c": 1.585,
    "v": 156550.94
  },
  {
    "t": 1723775400000,
    "o": 1.585,
    "h": 1.655,
    "l": 1.584,
    "c": 1.635,
    "v": 794552.51
  },
  {
    "t": 1723777200000,
    "o": 1.634,
    "h": 1.657,
    "l": 1.631,
    "c": 1.636,
    "v": 331945.94
  },
  {
    "t": 1723779000000,
    "o": 1.637,
    "h": 1.646,
    "l": 1.615,
    "c": 1.632,
    "v": 263194.81
  },
  {
    "t": 1723780800000,
    "o": 1.632,
    "h": 1.634,
    "l": 1.607,
    "c": 1.617,
    "v": 153369.14
  },
  {
    "t": 1723782600000,
    "o": 1.617,
    "h": 1.619,
    "l": 1.599,
    "c": 1.607,
    "v": 217943.87
  },
  {
    "t": 1723784400000,
    "o": 1.606,
    "h": 1.607,
    "l": 1.589,
    "c": 1.594,
    "v": 107619.70000000001
  },
  {
    "t": 1723786200000,
    "o": 1.594,
    "h": 1.6,
    "l": 1.588,
    "c": 1.594,
    "v": 63758.23999999999
  },
  {
    "t": 1723788000000,
    "o": 1.594,
    "h": 1.649,
    "l": 1.593,
    "c": 1.634,
    "v": 341388.61000000004
  },
  {
    "t": 1723789800000,
    "o": 1.634,
    "h": 1.637,
    "l": 1.611,
    "c": 1.625,
    "v": 525646.74
  },
  {
    "t": 1723791600000,
    "o": 1.625,
    "h": 1.625,
    "l": 1.597,
    "c": 1.61,
    "v": 214275.03000000003
  },
  {
    "t": 1723793400000,
    "o": 1.61,
    "h": 1.632,
    "l": 1.606,
    "c": 1.619,
    "v": 75523.59000000001
  },
  {
    "t": 1723795200000,
    "o": 1.619,
    "h": 1.63,
    "l": 1.605,
    "c": 1.607,
    "v": 81111.53
  },
  {
    "t": 1723797000000,
    "o": 1.606,
    "h": 1.63,
    "l": 1.602,
    "c": 1.624,
    "v": 81707.18999999999
  },
  {
    "t": 1723798800000,
    "o": 1.624,
    "h": 1.631,
    "l": 1.613,
    "c": 1.617,
    "v": 130841.94000000002
  },
  {
    "t": 1723800600000,
    "o": 1.617,
    "h": 1.649,
    "l": 1.617,
    "c": 1.646,
    "v": 151729.59999999998
  },
  {
    "t": 1723802400000,
    "o": 1.646,
    "h": 1.649,
    "l": 1.626,
    "c": 1.639,
    "v": 142998.63
  },
  {
    "t": 1723804200000,
    "o": 1.639,
    "h": 1.645,
    "l": 1.633,
    "c": 1.643,
    "v": 83674.22999999998
  },
  {
    "t": 1723806000000,
    "o": 1.643,
    "h": 1.649,
    "l": 1.633,
    "c": 1.635,
    "v": 77065.76000000001
  },
  {
    "t": 1723807800000,
    "o": 1.636,
    "h": 1.646,
    "l": 1.626,
    "c": 1.633,
    "v": 115109.83
  },
  {
    "t": 1723809600000,
    "o": 1.634,
    "h": 1.645,
    "l": 1.601,
    "c": 1.608,
    "v": 293690.04000000004
  },
  {
    "t": 1723811400000,
    "o": 1.609,
    "h": 1.609,
    "l": 1.576,
    "c": 1.583,
    "v": 386868.27999999997
  },
  {
    "t": 1723813200000,
    "o": 1.583,
    "h": 1.63,
    "l": 1.562,
    "c": 1.627,
    "v": 363663.93
  },
  {
    "t": 1723815000000,
    "o": 1.628,
    "h": 1.656,
    "l": 1.603,
    "c": 1.616,
    "v": 318701.11
  },
  {
    "t": 1723816800000,
    "o": 1.615,
    "h": 1.626,
    "l": 1.581,
    "c": 1.589,
    "v": 197710.90999999997
  },
  {
    "t": 1723818600000,
    "o": 1.589,
    "h": 1.608,
    "l": 1.581,
    "c": 1.588,
    "v": 179101.15
  },
  {
    "t": 1723820400000,
    "o": 1.588,
    "h": 1.608,
    "l": 1.571,
    "c": 1.577,
    "v": 203616.73
  },
  {
    "t": 1723822200000,
    "o": 1.577,
    "h": 1.578,
    "l": 1.558,
    "c": 1.576,
    "v": 329254.66000000003
  },
  {
    "t": 1723824000000,
    "o": 1.576,
    "h": 1.611,
    "l": 1.559,
    "c": 1.608,
    "v": 180339.15
  },
  {
    "t": 1723825800000,
    "o": 1.608,
    "h": 1.633,
    "l": 1.605,
    "c": 1.624,
    "v": 642816.43
  },
  {
    "t": 1723827600000,
    "o": 1.624,
    "h": 1.624,
    "l": 1.567,
    "c": 1.587,
    "v": 204331.06
  },
  {
    "t": 1723829400000,
    "o": 1.587,
    "h": 1.662,
    "l": 1.587,
    "c": 1.651,
    "v": 643019.09
  },
  {
    "t": 1723831200000,
    "o": 1.65,
    "h": 1.694,
    "l": 1.65,
    "c": 1.676,
    "v": 558692.8200000001
  },
  {
    "t": 1723833000000,
    "o": 1.676,
    "h": 1.698,
    "l": 1.665,
    "c": 1.671,
    "v": 235836.79000000004
  },
  {
    "t": 1723834800000,
    "o": 1.668,
    "h": 1.676,
    "l": 1.652,
    "c": 1.661,
    "v": 184720.15
  },
  {
    "t": 1723836600000,
    "o": 1.662,
    "h": 1.662,
    "l": 1.647,
    "c": 1.658,
    "v": 74257.98999999999
  },
  {
    "t": 1723838400000,
    "o": 1.659,
    "h": 1.699,
    "l": 1.658,
    "c": 1.698,
    "v": 302758.58
  },
  {
    "t": 1723840200000,
    "o": 1.698,
    "h": 1.703,
    "l": 1.682,
    "c": 1.685,
    "v": 245216.51
  },
  {
    "t": 1723842000000,
    "o": 1.685,
    "h": 1.715,
    "l": 1.684,
    "c": 1.712,
    "v": 245214.89
  },
  {
    "t": 1723843800000,
    "o": 1.713,
    "h": 1.716,
    "l": 1.694,
    "c": 1.704,
    "v": 277453.5
  },
  {
    "t": 1723845600000,
    "o": 1.705,
    "h": 1.706,
    "l": 1.684,
    "c": 1.688,
    "v": 214667.92000000004
  },
  {
    "t": 1723847400000,
    "o": 1.688,
    "h": 1.693,
    "l": 1.661,
    "c": 1.664,
    "v": 188973.06
  },
  {
    "t": 1723849200000,
    "o": 1.664,
    "h": 1.689,
    "l": 1.651,
    "c": 1.679,
    "v": 330490.42
  },
  {
    "t": 1723851000000,
    "o": 1.679,
    "h": 1.697,
    "l": 1.677,
    "c": 1.692,
    "v": 207207.59
  },
  {
    "t": 1723852800000,
    "o": 1.692,
    "h": 1.692,
    "l": 1.642,
    "c": 1.644,
    "v": 392868.14999999997
  },
  {
    "t": 1723854600000,
    "o": 1.644,
    "h": 1.663,
    "l": 1.636,
    "c": 1.661,
    "v": 234113.42
  },
  {
    "t": 1723856400000,
    "o": 1.66,
    "h": 1.68,
    "l": 1.658,
    "c": 1.669,
    "v": 128463.1
  },
  {
    "t": 1723858200000,
    "o": 1.67,
    "h": 1.676,
    "l": 1.662,
    "c": 1.667,
    "v": 90611.29000000001
  },
  {
    "t": 1723860000000,
    "o": 1.666,
    "h": 1.675,
    "l": 1.66,
    "c": 1.673,
    "v": 93287.29000000001
  },
  {
    "t": 1723861800000,
    "o": 1.673,
    "h": 1.681,
    "l": 1.663,
    "c": 1.663,
    "v": 94858.81999999999
  },
  {
    "t": 1723863600000,
    "o": 1.664,
    "h": 1.666,
    "l": 1.649,
    "c": 1.649,
    "v": 85130.38
  },
  {
    "t": 1723865400000,
    "o": 1.649,
    "h": 1.652,
    "l": 1.635,
    "c": 1.643,
    "v": 63105.92999999999
  },
  {
    "t": 1723867200000,
    "o": 1.643,
    "h": 1.655,
    "l": 1.638,
    "c": 1.647,
    "v": 107148.95000000001
  },
  {
    "t": 1723869000000,
    "o": 1.647,
    "h": 1.661,
    "l": 1.647,
    "c": 1.651,
    "v": 55111.32
  },
  {
    "t": 1723870800000,
    "o": 1.651,
    "h": 1.652,
    "l": 1.642,
    "c": 1.648,
    "v": 38996.43
  },
  {
    "t": 1723872600000,
    "o": 1.647,
    "h": 1.649,
    "l": 1.636,
    "c": 1.644,
    "v": 69219.25000000001
  },
  {
    "t": 1723874400000,
    "o": 1.645,
    "h": 1.674,
    "l": 1.643,
    "c": 1.661,
    "v": 228977.63
  },
  {
    "t": 1723876200000,
    "o": 1.661,
    "h": 1.661,
    "l": 1.636,
    "c": 1.638,
    "v": 198934.8
  },
  {
    "t": 1723878000000,
    "o": 1.639,
    "h": 1.642,
    "l": 1.625,
    "c": 1.635,
    "v": 197510.55
  },
  {
    "t": 1723879800000,
    "o": 1.634,
    "h": 1.644,
    "l": 1.63,
    "c": 1.644,
    "v": 84708.43
  },
  {
    "t": 1723881600000,
    "o": 1.644,
    "h": 1.656,
    "l": 1.637,
    "c": 1.64,
    "v": 140716.08000000002
  },
  {
    "t": 1723883400000,
    "o": 1.64,
    "h": 1.649,
    "l": 1.632,
    "c": 1.646,
    "v": 95501.96
  },
  {
    "t": 1723885200000,
    "o": 1.646,
    "h": 1.656,
    "l": 1.634,
    "c": 1.654,
    "v": 171068.11000000002
  },
  {
    "t": 1723887000000,
    "o": 1.655,
    "h": 1.691,
    "l": 1.654,
    "c": 1.686,
    "v": 863484.4600000001
  },
  {
    "t": 1723888800000,
    "o": 1.686,
    "h": 1.72,
    "l": 1.686,
    "c": 1.706,
    "v": 664369
  },
  {
    "t": 1723890600000,
    "o": 1.707,
    "h": 1.724,
    "l": 1.651,
    "c": 1.653,
    "v": 906927.72
  },
  {
    "t": 1723892400000,
    "o": 1.653,
    "h": 1.671,
    "l": 1.648,
    "c": 1.655,
    "v": 484337.55999999994
  },
  {
    "t": 1723894200000,
    "o": 1.656,
    "h": 1.657,
    "l": 1.638,
    "c": 1.644,
    "v": 250155.44999999998
  },
  {
    "t": 1723896000000,
    "o": 1.643,
    "h": 1.718,
    "l": 1.639,
    "c": 1.712,
    "v": 860382.78
  },
  {
    "t": 1723897800000,
    "o": 1.711,
    "h": 1.72,
    "l": 1.699,
    "c": 1.712,
    "v": 439586.39
  },
  {
    "t": 1723899600000,
    "o": 1.713,
    "h": 1.733,
    "l": 1.693,
    "c": 1.706,
    "v": 458686.74999999994
  },
  {
    "t": 1723901400000,
    "o": 1.706,
    "h": 1.734,
    "l": 1.688,
    "c": 1.719,
    "v": 516131.49
  },
  {
    "t": 1723903200000,
    "o": 1.72,
    "h": 1.733,
    "l": 1.7,
    "c": 1.708,
    "v": 352014.13
  },
  {
    "t": 1723905000000,
    "o": 1.707,
    "h": 1.73,
    "l": 1.703,
    "c": 1.718,
    "v": 212249.87999999998
  },
  {
    "t": 1723906800000,
    "o": 1.718,
    "h": 1.91,
    "l": 1.715,
    "c": 1.837,
    "v": 3656369.89
  },
  {
    "t": 1723908600000,
    "o": 1.836,
    "h": 1.877,
    "l": 1.768,
    "c": 1.779,
    "v": 1396836.53
  },
  {
    "t": 1723910400000,
    "o": 1.779,
    "h": 1.791,
    "l": 1.735,
    "c": 1.774,
    "v": 1218181.73
  },
  {
    "t": 1723912200000,
    "o": 1.773,
    "h": 1.774,
    "l": 1.741,
    "c": 1.755,
    "v": 334558.52
  },
  {
    "t": 1723914000000,
    "o": 1.755,
    "h": 1.787,
    "l": 1.749,
    "c": 1.766,
    "v": 461164.18999999994
  },
  {
    "t": 1723915800000,
    "o": 1.767,
    "h": 1.774,
    "l": 1.753,
    "c": 1.767,
    "v": 115196.48
  },
  {
    "t": 1723917600000,
    "o": 1.768,
    "h": 1.773,
    "l": 1.74,
    "c": 1.768,
    "v": 522719.61
  },
  {
    "t": 1723919400000,
    "o": 1.769,
    "h": 1.769,
    "l": 1.749,
    "c": 1.763,
    "v": 133370.52999999997
  },
  {
    "t": 1723921200000,
    "o": 1.764,
    "h": 1.768,
    "l": 1.75,
    "c": 1.757,
    "v": 103873.96
  },
  {
    "t": 1723923000000,
    "o": 1.757,
    "h": 1.758,
    "l": 1.745,
    "c": 1.75,
    "v": 123428.32
  },
  {
    "t": 1723924800000,
    "o": 1.751,
    "h": 1.77,
    "l": 1.729,
    "c": 1.737,
    "v": 675656.3999999999
  },
  {
    "t": 1723926600000,
    "o": 1.738,
    "h": 1.762,
    "l": 1.738,
    "c": 1.761,
    "v": 47356.44
  },
  {
    "t": 1723928400000,
    "o": 1.761,
    "h": 1.767,
    "l": 1.751,
    "c": 1.76,
    "v": 47212.03999999999
  },
  {
    "t": 1723930200000,
    "o": 1.759,
    "h": 1.759,
    "l": 1.745,
    "c": 1.754,
    "v": 46548.96
  },
  {
    "t": 1723932000000,
    "o": 1.754,
    "h": 1.768,
    "l": 1.742,
    "c": 1.743,
    "v": 100552.41
  },
  {
    "t": 1723933800000,
    "o": 1.743,
    "h": 1.749,
    "l": 1.737,
    "c": 1.747,
    "v": 90375.82
  },
  {
    "t": 1723935600000,
    "o": 1.747,
    "h": 1.758,
    "l": 1.744,
    "c": 1.744,
    "v": 99605.38999999998
  },
  {
    "t": 1723937400000,
    "o": 1.744,
    "h": 1.751,
    "l": 1.739,
    "c": 1.748,
    "v": 66797.01
  },
  {
    "t": 1723939200000,
    "o": 1.747,
    "h": 1.747,
    "l": 1.713,
    "c": 1.722,
    "v": 672695.5299999999
  },
  {
    "t": 1723941000000,
    "o": 1.722,
    "h": 1.738,
    "l": 1.718,
    "c": 1.72,
    "v": 200781.07
  },
  {
    "t": 1723942800000,
    "o": 1.72,
    "h": 1.723,
    "l": 1.7,
    "c": 1.719,
    "v": 299008.22
  },
  {
    "t": 1723944600000,
    "o": 1.719,
    "h": 1.719,
    "l": 1.695,
    "c": 1.697,
    "v": 233180.53000000003
  },
  {
    "t": 1723946400000,
    "o": 1.697,
    "h": 1.701,
    "l": 1.677,
    "c": 1.68,
    "v": 353265
  },
  {
    "t": 1723948200000,
    "o": 1.68,
    "h": 1.713,
    "l": 1.677,
    "c": 1.709,
    "v": 482870.89999999997
  },
  {
    "t": 1723950000000,
    "o": 1.709,
    "h": 1.719,
    "l": 1.697,
    "c": 1.698,
    "v": 473597.94999999995
  },
  {
    "t": 1723951800000,
    "o": 1.698,
    "h": 1.7,
    "l": 1.689,
    "c": 1.69,
    "v": 105998.62
  },
  {
    "t": 1723953600000,
    "o": 1.69,
    "h": 1.69,
    "l": 1.673,
    "c": 1.673,
    "v": 169631.76
  },
  {
    "t": 1723955400000,
    "o": 1.673,
    "h": 1.692,
    "l": 1.672,
    "c": 1.692,
    "v": 96500.45999999999
  },
  {
    "t": 1723957200000,
    "o": 1.692,
    "h": 1.7,
    "l": 1.679,
    "c": 1.68,
    "v": 200884.07000000004
  },
  {
    "t": 1723959000000,
    "o": 1.679,
    "h": 1.694,
    "l": 1.678,
    "c": 1.684,
    "v": 87408.37
  },
  {
    "t": 1723960800000,
    "o": 1.683,
    "h": 1.72,
    "l": 1.683,
    "c": 1.709,
    "v": 383306.06
  },
  {
    "t": 1723962600000,
    "o": 1.708,
    "h": 1.733,
    "l": 1.705,
    "c": 1.73,
    "v": 345097.9
  },
  {
    "t": 1723964400000,
    "o": 1.731,
    "h": 1.75,
    "l": 1.725,
    "c": 1.729,
    "v": 430548.11
  },
  {
    "t": 1723966200000,
    "o": 1.73,
    "h": 1.754,
    "l": 1.728,
    "c": 1.742,
    "v": 418776.26
  },
  {
    "t": 1723968000000,
    "o": 1.742,
    "h": 1.743,
    "l": 1.724,
    "c": 1.726,
    "v": 251040.3
  },
  {
    "t": 1723969800000,
    "o": 1.726,
    "h": 1.759,
    "l": 1.721,
    "c": 1.758,
    "v": 369800.04000000004
  },
  {
    "t": 1723971600000,
    "o": 1.759,
    "h": 1.79,
    "l": 1.746,
    "c": 1.773,
    "v": 896625.35
  },
  {
    "t": 1723973400000,
    "o": 1.773,
    "h": 1.8,
    "l": 1.767,
    "c": 1.767,
    "v": 508497.52999999997
  },
  {
    "t": 1723975200000,
    "o": 1.767,
    "h": 1.784,
    "l": 1.755,
    "c": 1.77,
    "v": 341668.69999999995
  },
  {
    "t": 1723977000000,
    "o": 1.77,
    "h": 1.787,
    "l": 1.747,
    "c": 1.76,
    "v": 513902.8
  },
  {
    "t": 1723978800000,
    "o": 1.761,
    "h": 1.761,
    "l": 1.725,
    "c": 1.727,
    "v": 525608.87
  },
  {
    "t": 1723980600000,
    "o": 1.727,
    "h": 1.737,
    "l": 1.717,
    "c": 1.732,
    "v": 316867.85
  },
  {
    "t": 1723982400000,
    "o": 1.731,
    "h": 1.731,
    "l": 1.709,
    "c": 1.723,
    "v": 181056.18
  },
  {
    "t": 1723984200000,
    "o": 1.724,
    "h": 1.753,
    "l": 1.712,
    "c": 1.72,
    "v": 700560.03
  },
  {
    "t": 1723986000000,
    "o": 1.721,
    "h": 1.737,
    "l": 1.713,
    "c": 1.727,
    "v": 238502.90999999997
  },
  {
    "t": 1723987800000,
    "o": 1.727,
    "h": 1.735,
    "l": 1.719,
    "c": 1.723,
    "v": 120876.55000000002
  },
  {
    "t": 1723989600000,
    "o": 1.723,
    "h": 1.73,
    "l": 1.714,
    "c": 1.727,
    "v": 125144.18000000001
  },
  {
    "t": 1723991400000,
    "o": 1.726,
    "h": 1.737,
    "l": 1.717,
    "c": 1.733,
    "v": 108942.84999999999
  },
  {
    "t": 1723993200000,
    "o": 1.733,
    "h": 1.761,
    "l": 1.714,
    "c": 1.76,
    "v": 705415.04
  },
  {
    "t": 1723995000000,
    "o": 1.76,
    "h": 1.769,
    "l": 1.749,
    "c": 1.76,
    "v": 220694.71000000002
  },
  {
    "t": 1723996800000,
    "o": 1.761,
    "h": 1.778,
    "l": 1.747,
    "c": 1.762,
    "v": 304867.0300000001
  },
  {
    "t": 1723998600000,
    "o": 1.762,
    "h": 1.767,
    "l": 1.749,
    "c": 1.75,
    "v": 371854.73
  },
  {
    "t": 1724000400000,
    "o": 1.749,
    "h": 1.758,
    "l": 1.739,
    "c": 1.743,
    "v": 182563.12999999998
  },
  {
    "t": 1724002200000,
    "o": 1.743,
    "h": 1.758,
    "l": 1.734,
    "c": 1.739,
    "v": 251275.43000000002
  },
  {
    "t": 1724004000000,
    "o": 1.741,
    "h": 1.743,
    "l": 1.735,
    "c": 1.74,
    "v": 74791.51999999999
  },
  {
    "t": 1724005800000,
    "o": 1.74,
    "h": 1.745,
    "l": 1.736,
    "c": 1.742,
    "v": 31842.36
  },
  {
    "t": 1724007600000,
    "o": 1.741,
    "h": 1.75,
    "l": 1.736,
    "c": 1.746,
    "v": 58611.37
  },
  {
    "t": 1724009400000,
    "o": 1.746,
    "h": 1.75,
    "l": 1.74,
    "c": 1.747,
    "v": 122361.66
  },
  {
    "t": 1724011200000,
    "o": 1.747,
    "h": 1.757,
    "l": 1.741,
    "c": 1.75,
    "v": 223037.59
  },
  {
    "t": 1724013000000,
    "o": 1.75,
    "h": 1.761,
    "l": 1.744,
    "c": 1.755,
    "v": 42559.229999999996
  },
  {
    "t": 1724014800000,
    "o": 1.756,
    "h": 1.763,
    "l": 1.749,
    "c": 1.755,
    "v": 111946.46000000002
  },
  {
    "t": 1724016600000,
    "o": 1.756,
    "h": 1.76,
    "l": 1.736,
    "c": 1.743,
    "v": 78843.22
  },
  {
    "t": 1724018400000,
    "o": 1.744,
    "h": 1.769,
    "l": 1.744,
    "c": 1.763,
    "v": 121512.10999999999
  },
  {
    "t": 1724020200000,
    "o": 1.764,
    "h": 1.764,
    "l": 1.744,
    "c": 1.745,
    "v": 46012.579999999994
  },
  {
    "t": 1724022000000,
    "o": 1.746,
    "h": 1.749,
    "l": 1.732,
    "c": 1.733,
    "v": 92455.83000000002
  },
  {
    "t": 1724023800000,
    "o": 1.733,
    "h": 1.74,
    "l": 1.717,
    "c": 1.724,
    "v": 488945.38
  },
  {
    "t": 1724025600000,
    "o": 1.724,
    "h": 1.724,
    "l": 1.691,
    "c": 1.702,
    "v": 645477.7
  },
  {
    "t": 1724027400000,
    "o": 1.702,
    "h": 1.706,
    "l": 1.692,
    "c": 1.693,
    "v": 124492
  },
  {
    "t": 1724029200000,
    "o": 1.693,
    "h": 1.711,
    "l": 1.69,
    "c": 1.711,
    "v": 118655.18
  },
  {
    "t": 1724031000000,
    "o": 1.71,
    "h": 1.735,
    "l": 1.709,
    "c": 1.722,
    "v": 217937.17
  },
  {
    "t": 1724032800000,
    "o": 1.723,
    "h": 1.725,
    "l": 1.705,
    "c": 1.708,
    "v": 127730.90999999999
  },
  {
    "t": 1724034600000,
    "o": 1.707,
    "h": 1.722,
    "l": 1.707,
    "c": 1.721,
    "v": 128803.62999999999
  },
  {
    "t": 1724036400000,
    "o": 1.722,
    "h": 1.723,
    "l": 1.707,
    "c": 1.712,
    "v": 76582
  },
  {
    "t": 1724038200000,
    "o": 1.71,
    "h": 1.718,
    "l": 1.706,
    "c": 1.708,
    "v": 70897.97
  },
  {
    "t": 1724040000000,
    "o": 1.707,
    "h": 1.709,
    "l": 1.7,
    "c": 1.702,
    "v": 109674.09
  },
  {
    "t": 1724041800000,
    "o": 1.702,
    "h": 1.702,
    "l": 1.686,
    "c": 1.69,
    "v": 150490.7
  },
  {
    "t": 1724043600000,
    "o": 1.69,
    "h": 1.69,
    "l": 1.669,
    "c": 1.671,
    "v": 208347.25
  },
  {
    "t": 1724045400000,
    "o": 1.671,
    "h": 1.682,
    "l": 1.664,
    "c": 1.671,
    "v": 173973.48
  },
  {
    "t": 1724047200000,
    "o": 1.671,
    "h": 1.675,
    "l": 1.655,
    "c": 1.667,
    "v": 259249.59
  },
  {
    "t": 1724049000000,
    "o": 1.667,
    "h": 1.689,
    "l": 1.665,
    "c": 1.678,
    "v": 199697.61
  },
  {
    "t": 1724050800000,
    "o": 1.678,
    "h": 1.69,
    "l": 1.668,
    "c": 1.67,
    "v": 165055.52000000002
  },
  {
    "t": 1724052600000,
    "o": 1.669,
    "h": 1.684,
    "l": 1.667,
    "c": 1.673,
    "v": 121518.65999999999
  },
  {
    "t": 1724054400000,
    "o": 1.672,
    "h": 1.681,
    "l": 1.671,
    "c": 1.676,
    "v": 89067.79
  },
  {
    "t": 1724056200000,
    "o": 1.676,
    "h": 1.704,
    "l": 1.675,
    "c": 1.698,
    "v": 177301.88
  },
  {
    "t": 1724058000000,
    "o": 1.699,
    "h": 1.731,
    "l": 1.699,
    "c": 1.72,
    "v": 353870.50999999995
  },
  {
    "t": 1724059800000,
    "o": 1.72,
    "h": 1.724,
    "l": 1.68,
    "c": 1.685,
    "v": 273031.63
  },
  {
    "t": 1724061600000,
    "o": 1.684,
    "h": 1.697,
    "l": 1.662,
    "c": 1.696,
    "v": 247494.16
  },
  {
    "t": 1724063400000,
    "o": 1.697,
    "h": 1.697,
    "l": 1.663,
    "c": 1.666,
    "v": 115610.22
  },
  {
    "t": 1724065200000,
    "o": 1.666,
    "h": 1.683,
    "l": 1.65,
    "c": 1.653,
    "v": 471382.55
  },
  {
    "t": 1724067000000,
    "o": 1.653,
    "h": 1.688,
    "l": 1.651,
    "c": 1.681,
    "v": 154416.45
  },
  {
    "t": 1724068800000,
    "o": 1.68,
    "h": 1.687,
    "l": 1.672,
    "c": 1.684,
    "v": 160542.93000000002
  },
  {
    "t": 1724070600000,
    "o": 1.683,
    "h": 1.688,
    "l": 1.668,
    "c": 1.682,
    "v": 99111.4
  },
  {
    "t": 1724072400000,
    "o": 1.682,
    "h": 1.707,
    "l": 1.68,
    "c": 1.692,
    "v": 277989.69
  },
  {
    "t": 1724074200000,
    "o": 1.693,
    "h": 1.702,
    "l": 1.657,
    "c": 1.66,
    "v": 373897.89
  },
  {
    "t": 1724076000000,
    "o": 1.66,
    "h": 1.66,
    "l": 1.636,
    "c": 1.643,
    "v": 492241.31
  },
  {
    "t": 1724077800000,
    "o": 1.643,
    "h": 1.662,
    "l": 1.639,
    "c": 1.66,
    "v": 347497.33
  },
  {
    "t": 1724079600000,
    "o": 1.661,
    "h": 1.669,
    "l": 1.654,
    "c": 1.659,
    "v": 90282.84000000001
  },
  {
    "t": 1724081400000,
    "o": 1.659,
    "h": 1.665,
    "l": 1.646,
    "c": 1.647,
    "v": 189768.46
  },
  {
    "t": 1724083200000,
    "o": 1.647,
    "h": 1.655,
    "l": 1.641,
    "c": 1.648,
    "v": 166987.65
  },
  {
    "t": 1724085000000,
    "o": 1.647,
    "h": 1.651,
    "l": 1.628,
    "c": 1.649,
    "v": 148545.18
  },
  {
    "t": 1724086800000,
    "o": 1.649,
    "h": 1.665,
    "l": 1.642,
    "c": 1.665,
    "v": 153479.22999999998
  },
  {
    "t": 1724088600000,
    "o": 1.666,
    "h": 1.72,
    "l": 1.665,
    "c": 1.681,
    "v": 519460.33
  },
  {
    "t": 1724090400000,
    "o": 1.682,
    "h": 1.685,
    "l": 1.666,
    "c": 1.673,
    "v": 90113.51
  },
  {
    "t": 1724092200000,
    "o": 1.673,
    "h": 1.692,
    "l": 1.669,
    "c": 1.679,
    "v": 103032.95
  },
  {
    "t": 1724094000000,
    "o": 1.68,
    "h": 1.7,
    "l": 1.676,
    "c": 1.697,
    "v": 72881.92
  },
  {
    "t": 1724095800000,
    "o": 1.696,
    "h": 1.699,
    "l": 1.685,
    "c": 1.693,
    "v": 69599.34
  },
  {
    "t": 1724097600000,
    "o": 1.693,
    "h": 1.719,
    "l": 1.692,
    "c": 1.707,
    "v": 677911.5499999999
  },
  {
    "t": 1724099400000,
    "o": 1.708,
    "h": 1.72,
    "l": 1.699,
    "c": 1.699,
    "v": 197236.44
  },
  {
    "t": 1724101200000,
    "o": 1.699,
    "h": 1.707,
    "l": 1.69,
    "c": 1.692,
    "v": 500414.04000000004
  },
  {
    "t": 1724103000000,
    "o": 1.693,
    "h": 1.712,
    "l": 1.691,
    "c": 1.703,
    "v": 89233.64000000001
  },
  {
    "t": 1724104800000,
    "o": 1.703,
    "h": 1.721,
    "l": 1.702,
    "c": 1.714,
    "v": 89306
  },
  {
    "t": 1724106600000,
    "o": 1.714,
    "h": 1.724,
    "l": 1.708,
    "c": 1.719,
    "v": 145253.82
  },
  {
    "t": 1724108400000,
    "o": 1.719,
    "h": 1.725,
    "l": 1.708,
    "c": 1.72,
    "v": 62650.810000000005
  },
  {
    "t": 1724110200000,
    "o": 1.72,
    "h": 1.731,
    "l": 1.72,
    "c": 1.728,
    "v": 75219.73000000001
  },
  {
    "t": 1724112000000,
    "o": 1.728,
    "h": 1.747,
    "l": 1.721,
    "c": 1.744,
    "v": 308609.56
  },
  {
    "t": 1724113800000,
    "o": 1.744,
    "h": 1.758,
    "l": 1.733,
    "c": 1.738,
    "v": 310420.77999999997
  },
  {
    "t": 1724115600000,
    "o": 1.739,
    "h": 1.758,
    "l": 1.731,
    "c": 1.75,
    "v": 208318.47
  },
  {
    "t": 1724117400000,
    "o": 1.751,
    "h": 1.752,
    "l": 1.727,
    "c": 1.729,
    "v": 178113.31000000003
  },
  {
    "t": 1724119200000,
    "o": 1.729,
    "h": 1.731,
    "l": 1.714,
    "c": 1.718,
    "v": 363522.39
  },
  {
    "t": 1724121000000,
    "o": 1.718,
    "h": 1.724,
    "l": 1.717,
    "c": 1.721,
    "v": 54743.04
  },
  {
    "t": 1724122800000,
    "o": 1.721,
    "h": 1.727,
    "l": 1.717,
    "c": 1.725,
    "v": 74553.72
  },
  {
    "t": 1724124600000,
    "o": 1.726,
    "h": 1.733,
    "l": 1.715,
    "c": 1.724,
    "v": 181722.64
  },
  {
    "t": 1724126400000,
    "o": 1.725,
    "h": 1.734,
    "l": 1.716,
    "c": 1.717,
    "v": 102295.95000000001
  },
  {
    "t": 1724128200000,
    "o": 1.716,
    "h": 1.743,
    "l": 1.715,
    "c": 1.74,
    "v": 124663.54000000001
  },
  {
    "t": 1724130000000,
    "o": 1.741,
    "h": 1.756,
    "l": 1.737,
    "c": 1.745,
    "v": 148360.32
  },
  {
    "t": 1724131800000,
    "o": 1.744,
    "h": 1.752,
    "l": 1.737,
    "c": 1.747,
    "v": 115067.13
  },
  {
    "t": 1724133600000,
    "o": 1.748,
    "h": 1.748,
    "l": 1.736,
    "c": 1.744,
    "v": 55610.34
  },
  {
    "t": 1724135400000,
    "o": 1.745,
    "h": 1.753,
    "l": 1.74,
    "c": 1.742,
    "v": 90991.59
  },
  {
    "t": 1724137200000,
    "o": 1.742,
    "h": 1.743,
    "l": 1.731,
    "c": 1.74,
    "v": 76911.49999999999
  },
  {
    "t": 1724139000000,
    "o": 1.741,
    "h": 1.75,
    "l": 1.726,
    "c": 1.73,
    "v": 276036.67000000004
  },
  {
    "t": 1724140800000,
    "o": 1.73,
    "h": 1.734,
    "l": 1.717,
    "c": 1.721,
    "v": 161471.81
  },
  {
    "t": 1724142600000,
    "o": 1.721,
    "h": 1.732,
    "l": 1.718,
    "c": 1.73,
    "v": 189087.86
  },
  {
    "t": 1724144400000,
    "o": 1.731,
    "h": 1.743,
    "l": 1.727,
    "c": 1.74,
    "v": 162111.37
  },
  {
    "t": 1724146200000,
    "o": 1.74,
    "h": 1.746,
    "l": 1.73,
    "c": 1.733,
    "v": 101206.20999999999
  },
  {
    "t": 1724148000000,
    "o": 1.733,
    "h": 1.738,
    "l": 1.727,
    "c": 1.733,
    "v": 57727.92999999999
  },
  {
    "t": 1724149800000,
    "o": 1.733,
    "h": 1.733,
    "l": 1.718,
    "c": 1.719,
    "v": 113977.32
  },
  {
    "t": 1724151600000,
    "o": 1.718,
    "h": 1.725,
    "l": 1.709,
    "c": 1.718,
    "v": 159733.43
  },
  {
    "t": 1724153400000,
    "o": 1.719,
    "h": 1.724,
    "l": 1.709,
    "c": 1.714,
    "v": 76004.24
  },
  {
    "t": 1724155200000,
    "o": 1.715,
    "h": 1.723,
    "l": 1.709,
    "c": 1.711,
    "v": 128624.70999999999
  },
  {
    "t": 1724157000000,
    "o": 1.71,
    "h": 1.714,
    "l": 1.703,
    "c": 1.705,
    "v": 127298.81
  },
  {
    "t": 1724158800000,
    "o": 1.705,
    "h": 1.705,
    "l": 1.69,
    "c": 1.696,
    "v": 175137.19
  },
  {
    "t": 1724160600000,
    "o": 1.696,
    "h": 1.723,
    "l": 1.683,
    "c": 1.71,
    "v": 451584.94999999995
  },
  {
    "t": 1724162400000,
    "o": 1.71,
    "h": 1.713,
    "l": 1.657,
    "c": 1.668,
    "v": 446032.14
  },
  {
    "t": 1724164200000,
    "o": 1.667,
    "h": 1.667,
    "l": 1.65,
    "c": 1.652,
    "v": 407235.73000000004
  },
  {
    "t": 1724166000000,
    "o": 1.652,
    "h": 1.654,
    "l": 1.62,
    "c": 1.646,
    "v": 765846.9199999999
  },
  {
    "t": 1724167800000,
    "o": 1.646,
    "h": 1.657,
    "l": 1.638,
    "c": 1.64,
    "v": 226445.81
  },
  {
    "t": 1724169600000,
    "o": 1.64,
    "h": 1.657,
    "l": 1.632,
    "c": 1.654,
    "v": 206322.26
  },
  {
    "t": 1724171400000,
    "o": 1.653,
    "h": 1.66,
    "l": 1.644,
    "c": 1.651,
    "v": 123290.34000000001
  },
  {
    "t": 1724173200000,
    "o": 1.65,
    "h": 1.657,
    "l": 1.647,
    "c": 1.652,
    "v": 40710.36
  },
  {
    "t": 1724175000000,
    "o": 1.652,
    "h": 1.663,
    "l": 1.649,
    "c": 1.658,
    "v": 54434.759999999995
  },
  {
    "t": 1724176800000,
    "o": 1.657,
    "h": 1.692,
    "l": 1.654,
    "c": 1.678,
    "v": 252857.65000000002
  },
  {
    "t": 1724178600000,
    "o": 1.678,
    "h": 1.684,
    "l": 1.671,
    "c": 1.679,
    "v": 59382.09
  },
  {
    "t": 1724180400000,
    "o": 1.678,
    "h": 1.68,
    "l": 1.674,
    "c": 1.679,
    "v": 23682.76
  },
  {
    "t": 1724182200000,
    "o": 1.677,
    "h": 1.685,
    "l": 1.674,
    "c": 1.677,
    "v": 28025.5
  },
  {
    "t": 1724184000000,
    "o": 1.677,
    "h": 1.686,
    "l": 1.676,
    "c": 1.682,
    "v": 39106.310000000005
  },
  {
    "t": 1724185800000,
    "o": 1.682,
    "h": 1.684,
    "l": 1.672,
    "c": 1.673,
    "v": 29861.4
  },
  {
    "t": 1724187600000,
    "o": 1.674,
    "h": 1.674,
    "l": 1.662,
    "c": 1.668,
    "v": 56593.02
  },
  {
    "t": 1724189400000,
    "o": 1.668,
    "h": 1.674,
    "l": 1.665,
    "c": 1.667,
    "v": 35739.43
  },
  {
    "t": 1724191200000,
    "o": 1.666,
    "h": 1.676,
    "l": 1.666,
    "c": 1.67,
    "v": 60568.82000000001
  },
  {
    "t": 1724193000000,
    "o": 1.668,
    "h": 1.675,
    "l": 1.666,
    "c": 1.673,
    "v": 51795.98999999999
  },
  {
    "t": 1724194800000,
    "o": 1.672,
    "h": 1.679,
    "l": 1.665,
    "c": 1.667,
    "v": 109932.12000000001
  },
  {
    "t": 1724196600000,
    "o": 1.666,
    "h": 1.677,
    "l": 1.661,
    "c": 1.664,
    "v": 156074.76
  },
  {
    "t": 1724198400000,
    "o": 1.664,
    "h": 1.678,
    "l": 1.662,
    "c": 1.675,
    "v": 73363.21
  },
  {
    "t": 1724200200000,
    "o": 1.675,
    "h": 1.675,
    "l": 1.649,
    "c": 1.651,
    "v": 113324.68999999999
  },
  {
    "t": 1724202000000,
    "o": 1.651,
    "h": 1.664,
    "l": 1.643,
    "c": 1.656,
    "v": 109866.40999999999
  },
  {
    "t": 1724203800000,
    "o": 1.656,
    "h": 1.663,
    "l": 1.648,
    "c": 1.66,
    "v": 52176.45000000001
  },
  {
    "t": 1724205600000,
    "o": 1.66,
    "h": 1.664,
    "l": 1.651,
    "c": 1.652,
    "v": 41065.9
  },
  {
    "t": 1724207400000,
    "o": 1.653,
    "h": 1.671,
    "l": 1.651,
    "c": 1.665,
    "v": 197272.05000000002
  },
  {
    "t": 1724209200000,
    "o": 1.664,
    "h": 1.666,
    "l": 1.651,
    "c": 1.654,
    "v": 95269.59
  },
  {
    "t": 1724211000000,
    "o": 1.654,
    "h": 1.671,
    "l": 1.653,
    "c": 1.67,
    "v": 179871.90000000002
  },
  {
    "t": 1724212800000,
    "o": 1.669,
    "h": 1.669,
    "l": 1.658,
    "c": 1.664,
    "v": 123971.11
  },
  {
    "t": 1724214600000,
    "o": 1.664,
    "h": 1.67,
    "l": 1.664,
    "c": 1.667,
    "v": 37641.03999999999
  },
  {
    "t": 1724216400000,
    "o": 1.667,
    "h": 1.678,
    "l": 1.667,
    "c": 1.673,
    "v": 76087.79
  },
  {
    "t": 1724218200000,
    "o": 1.674,
    "h": 1.676,
    "l": 1.668,
    "c": 1.67,
    "v": 35415.88
  },
  {
    "t": 1724220000000,
    "o": 1.67,
    "h": 1.687,
    "l": 1.667,
    "c": 1.685,
    "v": 82909.06
  },
  {
    "t": 1724221800000,
    "o": 1.685,
    "h": 1.685,
    "l": 1.666,
    "c": 1.666,
    "v": 134095.18
  },
  {
    "t": 1724223600000,
    "o": 1.666,
    "h": 1.679,
    "l": 1.665,
    "c": 1.678,
    "v": 99055.54000000001
  },
  {
    "t": 1724225400000,
    "o": 1.679,
    "h": 1.679,
    "l": 1.668,
    "c": 1.675,
    "v": 40541.630000000005
  },
  {
    "t": 1724227200000,
    "o": 1.675,
    "h": 1.679,
    "l": 1.65,
    "c": 1.656,
    "v": 203011.08000000002
  },
  {
    "t": 1724229000000,
    "o": 1.656,
    "h": 1.658,
    "l": 1.636,
    "c": 1.645,
    "v": 147442.74
  },
  {
    "t": 1724230800000,
    "o": 1.646,
    "h": 1.648,
    "l": 1.629,
    "c": 1.634,
    "v": 295241.96
  },
  {
    "t": 1724232600000,
    "o": 1.633,
    "h": 1.644,
    "l": 1.629,
    "c": 1.639,
    "v": 93815.27
  },
  {
    "t": 1724234400000,
    "o": 1.639,
    "h": 1.639,
    "l": 1.606,
    "c": 1.62,
    "v": 328568.82000000007
  },
  {
    "t": 1724236200000,
    "o": 1.621,
    "h": 1.633,
    "l": 1.617,
    "c": 1.629,
    "v": 152869.75999999998
  },
  {
    "t": 1724238000000,
    "o": 1.628,
    "h": 1.63,
    "l": 1.599,
    "c": 1.609,
    "v": 474971.70999999996
  },
  {
    "t": 1724239800000,
    "o": 1.609,
    "h": 1.614,
    "l": 1.599,
    "c": 1.61,
    "v": 428991.55
  },
  {
    "t": 1724241600000,
    "o": 1.61,
    "h": 1.611,
    "l": 1.581,
    "c": 1.591,
    "v": 270023.38999999996
  },
  {
    "t": 1724243400000,
    "o": 1.591,
    "h": 1.597,
    "l": 1.585,
    "c": 1.589,
    "v": 167739.99
  },
  {
    "t": 1724245200000,
    "o": 1.588,
    "h": 1.593,
    "l": 1.582,
    "c": 1.586,
    "v": 175089.68
  },
  {
    "t": 1724247000000,
    "o": 1.586,
    "h": 1.605,
    "l": 1.552,
    "c": 1.6,
    "v": 815934.12
  },
  {
    "t": 1724248800000,
    "o": 1.601,
    "h": 1.609,
    "l": 1.574,
    "c": 1.587,
    "v": 384703.14999999997
  },
  {
    "t": 1724250600000,
    "o": 1.587,
    "h": 1.603,
    "l": 1.57,
    "c": 1.576,
    "v": 231407.34999999998
  },
  {
    "t": 1724252400000,
    "o": 1.576,
    "h": 1.585,
    "l": 1.57,
    "c": 1.58,
    "v": 164781.95
  },
  {
    "t": 1724254200000,
    "o": 1.581,
    "h": 1.591,
    "l": 1.58,
    "c": 1.586,
    "v": 148593.45
  },
  {
    "t": 1724256000000,
    "o": 1.586,
    "h": 1.593,
    "l": 1.578,
    "c": 1.589,
    "v": 154171.95
  },
  {
    "t": 1724257800000,
    "o": 1.59,
    "h": 1.603,
    "l": 1.589,
    "c": 1.598,
    "v": 127690.31999999999
  },
  {
    "t": 1724259600000,
    "o": 1.598,
    "h": 1.612,
    "l": 1.596,
    "c": 1.606,
    "v": 198655.31
  },
  {
    "t": 1724261400000,
    "o": 1.606,
    "h": 1.615,
    "l": 1.602,
    "c": 1.608,
    "v": 159420.77
  },
  {
    "t": 1724263200000,
    "o": 1.609,
    "h": 1.638,
    "l": 1.596,
    "c": 1.636,
    "v": 317755.76
  },
  {
    "t": 1724265000000,
    "o": 1.635,
    "h": 1.651,
    "l": 1.623,
    "c": 1.638,
    "v": 392397.6
  },
  {
    "t": 1724266800000,
    "o": 1.638,
    "h": 1.651,
    "l": 1.634,
    "c": 1.64,
    "v": 187126.59
  },
  {
    "t": 1724268600000,
    "o": 1.64,
    "h": 1.654,
    "l": 1.637,
    "c": 1.652,
    "v": 120817.26
  },
  {
    "t": 1724270400000,
    "o": 1.653,
    "h": 1.699,
    "l": 1.653,
    "c": 1.691,
    "v": 805671.7599999999
  },
  {
    "t": 1724272200000,
    "o": 1.691,
    "h": 1.697,
    "l": 1.668,
    "c": 1.682,
    "v": 256933.72
  },
  {
    "t": 1724274000000,
    "o": 1.682,
    "h": 1.687,
    "l": 1.66,
    "c": 1.681,
    "v": 565299.05
  },
  {
    "t": 1724275800000,
    "o": 1.681,
    "h": 1.699,
    "l": 1.681,
    "c": 1.695,
    "v": 129076.07
  },
  {
    "t": 1724277600000,
    "o": 1.695,
    "h": 1.699,
    "l": 1.68,
    "c": 1.683,
    "v": 117552.31999999999
  },
  {
    "t": 1724279400000,
    "o": 1.682,
    "h": 1.692,
    "l": 1.682,
    "c": 1.685,
    "v": 95476.54999999999
  },
  {
    "t": 1724281200000,
    "o": 1.685,
    "h": 1.699,
    "l": 1.683,
    "c": 1.683,
    "v": 131992.08
  },
  {
    "t": 1724283000000,
    "o": 1.683,
    "h": 1.687,
    "l": 1.677,
    "c": 1.683,
    "v": 94732.34000000001
  },
  {
    "t": 1724284800000,
    "o": 1.683,
    "h": 1.703,
    "l": 1.681,
    "c": 1.694,
    "v": 161743.34
  },
  {
    "t": 1724286600000,
    "o": 1.695,
    "h": 1.712,
    "l": 1.693,
    "c": 1.693,
    "v": 181208.14
  },
  {
    "t": 1724288400000,
    "o": 1.694,
    "h": 1.705,
    "l": 1.688,
    "c": 1.694,
    "v": 156110.87
  },
  {
    "t": 1724290200000,
    "o": 1.693,
    "h": 1.711,
    "l": 1.69,
    "c": 1.71,
    "v": 144703.13999999998
  },
  {
    "t": 1724292000000,
    "o": 1.71,
    "h": 1.716,
    "l": 1.692,
    "c": 1.692,
    "v": 156773.65
  },
  {
    "t": 1724293800000,
    "o": 1.693,
    "h": 1.703,
    "l": 1.669,
    "c": 1.685,
    "v": 305753.66000000003
  },
  {
    "t": 1724295600000,
    "o": 1.684,
    "h": 1.7,
    "l": 1.675,
    "c": 1.697,
    "v": 220021.93
  },
  {
    "t": 1724297400000,
    "o": 1.697,
    "h": 1.703,
    "l": 1.691,
    "c": 1.703,
    "v": 140314.5
  },
  {
    "t": 1724299200000,
    "o": 1.703,
    "h": 1.706,
    "l": 1.686,
    "c": 1.688,
    "v": 178326.28999999998
  },
  {
    "t": 1724301000000,
    "o": 1.688,
    "h": 1.698,
    "l": 1.685,
    "c": 1.697,
    "v": 86701.89
  },
  {
    "t": 1724302800000,
    "o": 1.696,
    "h": 1.706,
    "l": 1.686,
    "c": 1.701,
    "v": 239205.90000000002
  },
  {
    "t": 1724304600000,
    "o": 1.702,
    "h": 1.702,
    "l": 1.69,
    "c": 1.693,
    "v": 52974.84000000001
  },
  {
    "t": 1724306400000,
    "o": 1.693,
    "h": 1.698,
    "l": 1.686,
    "c": 1.688,
    "v": 85917.78
  },
  {
    "t": 1724308200000,
    "o": 1.689,
    "h": 1.713,
    "l": 1.688,
    "c": 1.696,
    "v": 208407.52
  },
  {
    "t": 1724310000000,
    "o": 1.697,
    "h": 1.717,
    "l": 1.69,
    "c": 1.712,
    "v": 271508.99
  },
  {
    "t": 1724311800000,
    "o": 1.713,
    "h": 1.757,
    "l": 1.713,
    "c": 1.753,
    "v": 384065.16000000003
  },
  {
    "t": 1724313600000,
    "o": 1.753,
    "h": 1.799,
    "l": 1.752,
    "c": 1.793,
    "v": 938184.7
  },
  {
    "t": 1724315400000,
    "o": 1.792,
    "h": 1.816,
    "l": 1.777,
    "c": 1.805,
    "v": 893766.05
  },
  {
    "t": 1724317200000,
    "o": 1.805,
    "h": 1.838,
    "l": 1.79,
    "c": 1.806,
    "v": 892841.4800000001
  },
  {
    "t": 1724319000000,
    "o": 1.807,
    "h": 1.833,
    "l": 1.806,
    "c": 1.819,
    "v": 694006.33
  },
  {
    "t": 1724320800000,
    "o": 1.819,
    "h": 1.823,
    "l": 1.8,
    "c": 1.812,
    "v": 388105.79
  },
  {
    "t": 1724322600000,
    "o": 1.812,
    "h": 1.993,
    "l": 1.798,
    "c": 1.967,
    "v": 3194699.6399999997
  },
  {
    "t": 1724324400000,
    "o": 1.967,
    "h": 1.994,
    "l": 1.916,
    "c": 1.969,
    "v": 3775150.16
  },
  {
    "t": 1724326200000,
    "o": 1.969,
    "h": 1.971,
    "l": 1.884,
    "c": 1.905,
    "v": 2420011.98
  },
  {
    "t": 1724328000000,
    "o": 1.905,
    "h": 1.93,
    "l": 1.853,
    "c": 1.863,
    "v": 2737009.15
  },
  {
    "t": 1724329800000,
    "o": 1.863,
    "h": 1.883,
    "l": 1.842,
    "c": 1.873,
    "v": 1021570.9400000001
  },
  {
    "t": 1724331600000,
    "o": 1.873,
    "h": 1.892,
    "l": 1.858,
    "c": 1.858,
    "v": 594226.76
  },
  {
    "t": 1724333400000,
    "o": 1.859,
    "h": 1.871,
    "l": 1.826,
    "c": 1.855,
    "v": 954596.2799999999
  },
  {
    "t": 1724335200000,
    "o": 1.855,
    "h": 1.876,
    "l": 1.846,
    "c": 1.868,
    "v": 421846.48000000004
  },
  {
    "t": 1724337000000,
    "o": 1.868,
    "h": 1.889,
    "l": 1.854,
    "c": 1.879,
    "v": 281510.45
  },
  {
    "t": 1724338800000,
    "o": 1.88,
    "h": 1.96,
    "l": 1.88,
    "c": 1.942,
    "v": 1543009.43
  },
  {
    "t": 1724340600000,
    "o": 1.943,
    "h": 1.948,
    "l": 1.909,
    "c": 1.944,
    "v": 865048.2699999999
  },
  {
    "t": 1724342400000,
    "o": 1.943,
    "h": 1.97,
    "l": 1.932,
    "c": 1.959,
    "v": 640399.53
  },
  {
    "t": 1724344200000,
    "o": 1.959,
    "h": 2.036,
    "l": 1.952,
    "c": 2.011,
    "v": 1834003.58
  },
  {
    "t": 1724346000000,
    "o": 2.01,
    "h": 2.015,
    "l": 1.976,
    "c": 1.995,
    "v": 842069.6500000001
  },
  {
    "t": 1724347800000,
    "o": 1.994,
    "h": 2.002,
    "l": 1.971,
    "c": 1.977,
    "v": 397587.88
  },
  {
    "t": 1724349600000,
    "o": 1.976,
    "h": 1.99,
    "l": 1.939,
    "c": 1.986,
    "v": 425071.92
  },
  {
    "t": 1724351400000,
    "o": 1.985,
    "h": 2.016,
    "l": 1.979,
    "c": 1.995,
    "v": 652206.3
  },
  {
    "t": 1724353200000,
    "o": 1.994,
    "h": 2.003,
    "l": 1.98,
    "c": 1.987,
    "v": 375650.92
  },
  {
    "t": 1724355000000,
    "o": 1.987,
    "h": 2.015,
    "l": 1.987,
    "c": 1.994,
    "v": 363016.95999999996
  },
  {
    "t": 1724356800000,
    "o": 1.994,
    "h": 2.015,
    "l": 1.984,
    "c": 1.988,
    "v": 305326.91
  },
  {
    "t": 1724358600000,
    "o": 1.988,
    "h": 2.012,
    "l": 1.979,
    "c": 2.004,
    "v": 212345.39999999997
  },
  {
    "t": 1724360400000,
    "o": 2.004,
    "h": 2.027,
    "l": 1.995,
    "c": 2.024,
    "v": 322495.87
  },
  {
    "t": 1724362200000,
    "o": 2.025,
    "h": 2.025,
    "l": 2,
    "c": 2.013,
    "v": 265001.01
  },
  {
    "t": 1724364000000,
    "o": 2.012,
    "h": 2.022,
    "l": 1.985,
    "c": 2.018,
    "v": 299427.9
  },
  {
    "t": 1724365800000,
    "o": 2.017,
    "h": 2.019,
    "l": 1.999,
    "c": 2.01,
    "v": 256002.88000000003
  },
  {
    "t": 1724367600000,
    "o": 2.01,
    "h": 2.014,
    "l": 1.983,
    "c": 2.01,
    "v": 332151.78
  },
  {
    "t": 1724369400000,
    "o": 2.01,
    "h": 2.023,
    "l": 2.007,
    "c": 2.014,
    "v": 288406.53
  },
  {
    "t": 1724371200000,
    "o": 2.013,
    "h": 2.016,
    "l": 1.993,
    "c": 2.011,
    "v": 427623.19000000006
  },
  {
    "t": 1724373000000,
    "o": 2.01,
    "h": 2.042,
    "l": 1.991,
    "c": 2.011,
    "v": 578286.73
  },
  {
    "t": 1724374800000,
    "o": 2.011,
    "h": 2.017,
    "l": 1.971,
    "c": 2.004,
    "v": 696985.1699999998
  },
  {
    "t": 1724376600000,
    "o": 2.003,
    "h": 2.027,
    "l": 1.998,
    "c": 2.013,
    "v": 397349.35000000003
  },
  {
    "t": 1724378400000,
    "o": 2.013,
    "h": 2.029,
    "l": 2.006,
    "c": 2.014,
    "v": 236980.06
  },
  {
    "t": 1724380200000,
    "o": 2.014,
    "h": 2.02,
    "l": 1.995,
    "c": 2.003,
    "v": 271594.33
  },
  {
    "t": 1724382000000,
    "o": 2.004,
    "h": 2.005,
    "l": 1.987,
    "c": 1.99,
    "v": 208294.66999999998
  },
  {
    "t": 1724383800000,
    "o": 1.989,
    "h": 2.029,
    "l": 1.989,
    "c": 2.019,
    "v": 249974.91
  },
  {
    "t": 1724385600000,
    "o": 2.019,
    "h": 2.036,
    "l": 2.014,
    "c": 2.027,
    "v": 343252.80000000005
  },
  {
    "t": 1724387400000,
    "o": 2.027,
    "h": 2.079,
    "l": 2.016,
    "c": 2.024,
    "v": 701387.6399999999
  },
  {
    "t": 1724389200000,
    "o": 2.025,
    "h": 2.088,
    "l": 2.011,
    "c": 2.034,
    "v": 1272423.3900000001
  },
  {
    "t": 1724391000000,
    "o": 2.035,
    "h": 2.046,
    "l": 2.005,
    "c": 2.017,
    "v": 1036662.0200000001
  },
  {
    "t": 1724392800000,
    "o": 2.016,
    "h": 2.039,
    "l": 2.005,
    "c": 2.02,
    "v": 296955.46
  },
  {
    "t": 1724394600000,
    "o": 2.019,
    "h": 2.044,
    "l": 2.018,
    "c": 2.025,
    "v": 321228.41
  },
  {
    "t": 1724396400000,
    "o": 2.026,
    "h": 2.035,
    "l": 2,
    "c": 2.005,
    "v": 331012.78
  },
  {
    "t": 1724398200000,
    "o": 2.005,
    "h": 2.026,
    "l": 1.991,
    "c": 2.021,
    "v": 388412.78
  },
  {
    "t": 1724400000000,
    "o": 2.021,
    "h": 2.025,
    "l": 2.003,
    "c": 2.013,
    "v": 317313.39
  },
  {
    "t": 1724401800000,
    "o": 2.012,
    "h": 2.03,
    "l": 2.011,
    "c": 2.027,
    "v": 259852.95
  },
  {
    "t": 1724403600000,
    "o": 2.026,
    "h": 2.027,
    "l": 2.015,
    "c": 2.017,
    "v": 172916.37999999998
  },
  {
    "t": 1724405400000,
    "o": 2.017,
    "h": 2.018,
    "l": 1.996,
    "c": 1.996,
    "v": 260075.13
  },
  {
    "t": 1724407200000,
    "o": 1.996,
    "h": 2.004,
    "l": 1.993,
    "c": 2.001,
    "v": 93028.73
  }
]

const orders = [
  {
    "time": 1723811400000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.604,
    "amount": 320.67251783440133,
    "id": 672821,
    "cost": 514.3587186063797,
    "toCancel": true,
    "fees": 0.5143587186063797,
    "baseValue": 514.8730773249861,
    "costProceed": -514.8730773249861,
    "averagePrice": 0,
    "pnlPrice": 1.7756159513118963,
    "balance": 0,
    "baseBalance": 4506.725969100091,
    "inventory_cost": 1.604,
    "ABP": 1.6072128184240295,
    "pnl": 0
  },
  {
    "time": 1723831800000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.66629344",
    "amount": 208.22869945576852,
    "id": 392946,
    "cost": 346.9701159228787,
    "fees": 0.3469701159228787,
    "baseValue": 346.6231458069558,
    "costProceed": 346.6231458069558,
    "averagePrice": 0,
    "pnlPrice": 1.8418179380875308,
    "balance": -208.22869945576852,
    "baseBalance": 4853.69608502297,
    "inventory_cost": 1.6039999999999999,
    "ABP": 1.6072128184240297,
    "pnl": 12.290313045976001
  },
  {
    "time": 1723845900000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.68909858",
    "amount": 112.44381837863281,
    "id": 736519,
    "cost": 189.92869395312658,
    "fees": 0.1899286939531266,
    "baseValue": 189.73876525917345,
    "costProceed": 189.73876525917345,
    "averagePrice": 0,
    "pnlPrice": 1.9143795701394428,
    "balance": -320.67251783440133,
    "baseBalance": 5043.624778976096,
    "inventory_cost": 0,
    "ABP": 0,
    "pnl": 9.288645869847436
  },
  {
    "time": 1723849200000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.6564999999999999,
    "amount": 458.4634042977129,
    "id": 833103,
    "cost": 759.4446292191614,
    "toCancel": true,
    "fees": 0.7594446292191613,
    "baseValue": 760.2040738483805,
    "costProceed": -760.2040738483805,
    "averagePrice": 0,
    "pnlPrice": 1.7466518049510273,
    "balance": 137.79088646331155,
    "baseBalance": 4284.180149756935,
    "inventory_cost": 1.6564999999999999,
    "ABP": 1.6598179761342922,
    "pnl": 0
  },
  {
    "time": 1723891200000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.70331312",
    "amount": 297.7032115807199,
    "id": 264221,
    "cost": 507.0817861515761,
    "fees": 0.5070817861515762,
    "baseValue": 506.57470436542457,
    "costProceed": 506.57470436542457,
    "averagePrice": 0,
    "pnlPrice": 1.7812120345507318,
    "balance": -159.91232511740833,
    "baseBalance": 4791.261935908511,
    "inventory_cost": 1.6565,
    "ABP": 1.6598179761342924,
    "pnl": 12.936189011978637
  },
  {
    "time": 1723900500000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.70011832",
    "amount": 104.38963114077941,
    "id": 957477,
    "cost": 177.47472432048158,
    "fees": 0.17747472432048159,
    "baseValue": 177.2972495961611,
    "costProceed": 177.2972495961611,
    "averagePrice": 0,
    "pnlPrice": 1.810783766864778,
    "balance": -264.3019562581877,
    "baseBalance": 4968.736660228993,
    "inventory_cost": 1.6565000000000003,
    "ABP": 1.6598179761342933,
    "pnl": 4.315303113065376
  },
  {
    "time": 1723908000000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.84627013",
    "amount": 56.37056157621359,
    "id": 649823,
    "cost": 104.07528404948887,
    "fees": 0.10407528404948888,
    "baseValue": 103.97120876543939,
    "costProceed": 103.97120876543939,
    "averagePrice": 0,
    "pnlPrice": 1.8033740709400703,
    "balance": -320.67251783440133,
    "baseBalance": 5072.811944278482,
    "inventory_cost": 0,
    "ABP": 0,
    "pnl": 10.49506423302662
  },
  {
    "time": 1723934400000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.73825,
    "amount": 457.03324698927753,
    "id": 845469,
    "cost": 794.4380415791117,
    "toCancel": true,
    "fees": 0.7944380415791117,
    "baseValue": 795.2324796206908,
    "costProceed": -795.2324796206908,
    "averagePrice": 0,
    "pnlPrice": 1.761925484395057,
    "balance": 136.3607291548762,
    "baseBalance": 4278.37390269937,
    "inventory_cost": 1.73825,
    "ABP": 1.7417317217117019,
    "pnl": 0
  },
  {
    "time": 1723944000000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.7065000000000001,
    "amount": 491.81636066622804,
    "id": 848666,
    "cost": 839.2846194769182,
    "toCancel": true,
    "fees": 0.8392846194769182,
    "baseValue": 840.1239040963951,
    "costProceed": -840.1239040963951,
    "averagePrice": 0,
    "pnlPrice": 1.7397377196258612,
    "balance": 628.1770898211042,
    "baseBalance": 3439.0892832224517,
    "inventory_cost": 1.7217930511588282,
    "ABP": 1.7252418095360906,
    "pnl": 0
  },
  {
    "time": 1723971900000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.74656079",
    "amount": 319.3609537986152,
    "id": 544555,
    "cost": 557.7833197616628,
    "fees": 0.5577833197616628,
    "baseValue": 557.2255364419011,
    "costProceed": 557.2255364419011,
    "averagePrice": 0,
    "pnlPrice": 1.7385166522736386,
    "balance": 308.81613602248905,
    "baseBalance": 3996.8726029841146,
    "inventory_cost": 1.7134438825974412,
    "ABP": 1.7330277700271683,
    "pnl": 11.691079316906977
  },
  {
    "time": 1723980300000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.73,
    "amount": 338.2470441220447,
    "id": 734858,
    "cost": 585.1673863311373,
    "toCancel": true,
    "fees": 0.5851673863311373,
    "baseValue": 585.7525537174685,
    "costProceed": -585.7525537174685,
    "averagePrice": 0,
    "pnlPrice": 1.7366189057814096,
    "balance": 647.0631801445338,
    "baseBalance": 3411.7052166529775,
    "inventory_cost": 1.7192306462615785,
    "ABP": 1.7331807118368927,
    "pnl": 0
  },
  {
    "time": 1723998900000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.75751887",
    "amount": 111.9839184494844,
    "id": 890139,
    "cost": 196.81384981150998,
    "fees": 0.19681384981150998,
    "baseValue": 196.61703596169846,
    "costProceed": 196.61703596169846,
    "averagePrice": 0,
    "pnlPrice": 1.734845078442188,
    "balance": 535.0792616950494,
    "baseBalance": 3608.5190664644874,
    "inventory_cost": 1.7167417682843962,
    "ABP": 1.736229445775253,
    "pnl": 5.449593932761402
  },
  {
    "time": 1724022600000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.739716571969697,
    "amount": 295.2360454373156,
    "id": 568567,
    "cost": 513.6270408900964,
    "toCancel": true,
    "fees": 0.5136270408900964,
    "baseValue": 514.1406679309865,
    "costProceed": -514.1406679309865,
    "averagePrice": 0,
    "pnlPrice": 1.736246397284271,
    "balance": 830.315307132365,
    "baseBalance": 3094.892025574391,
    "inventory_cost": 1.7226349584968492,
    "ABP": 1.7380184263039116,
    "pnl": 0
  },
  {
    "time": 1724076300000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.6505,
    "amount": 348.49159799817386,
    "id": 238132,
    "cost": 575.185382495986,
    "toCancel": true,
    "fees": 0.575185382495986,
    "baseValue": 575.760567878482,
    "costProceed": -575.760567878482,
    "averagePrice": 0,
    "pnlPrice": 1.7194167147288233,
    "balance": 1178.8069051305388,
    "baseBalance": 2519.7066430784052,
    "inventory_cost": 1.7058701889554357,
    "ABP": 1.7184411048730035,
    "pnl": 0
  },
  {
    "time": 1724089200000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.69853610",
    "amount": 261.10732980013177,
    "id": 980223,
    "cost": 443.5002256401296,
    "fees": 0.4435002256401296,
    "baseValue": 443.05672541448945,
    "costProceed": 443.05672541448945,
    "averagePrice": 0,
    "pnlPrice": 1.7236988169963565,
    "balance": 917.699575330407,
    "baseBalance": 2963.2068687185347,
    "inventory_cost": 1.7050787837036807,
    "ABP": 1.7320905284302492,
    "pnl": 11.66811993153687
  },
  {
    "time": 1724101500000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.69280760",
    "amount": 56.742974554398636,
    "id": 647856,
    "cost": 96.05493857229263,
    "fees": 0.09605493857229264,
    "baseValue": 95.95888363372033,
    "costProceed": 95.95888363372033,
    "averagePrice": 0,
    "pnlPrice": 1.72506505914368,
    "balance": 860.9566007760084,
    "baseBalance": 3059.261807290827,
    "inventory_cost": 1.7050105355208387,
    "ABP": 1.7358559821933635,
    "pnl": 2.2811905618098747
  },
  {
    "time": 1724114700000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.74144522",
    "amount": 30.610652349999814,
    "id": 896766,
    "cost": 53.30677421598894,
    "fees": 0.053306774215988936,
    "baseValue": 53.25346744177295,
    "costProceed": 53.25346744177295,
    "averagePrice": 0,
    "pnlPrice": 1.7247815898010808,
    "balance": 830.3459484260086,
    "baseBalance": 3112.568581506816,
    "inventory_cost": 1.7049709240978286,
    "ABP": 1.7380261991565398,
    "pnl": 2.708369430360179
  },
  {
    "time": 1724152800000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.7125,
    "amount": 289.9164567710165,
    "id": 651129,
    "cost": 496.4819322203657,
    "toCancel": true,
    "fees": 0.49648193222036574,
    "baseValue": 496.9784141525861,
    "costProceed": -496.9784141525861,
    "averagePrice": 0,
    "pnlPrice": 1.7229607521336232,
    "balance": 1120.2624051970251,
    "baseBalance": 2616.08664928645,
    "inventory_cost": 1.7064857759994851,
    "ABP": 1.7335780579033617,
    "pnl": 0
  },
  {
    "time": 1724164200000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.6644999999999999,
    "amount": 302.6772700585794,
    "id": 364608,
    "cost": 503.80631601250536,
    "toCancel": true,
    "fees": 0.5038063160125054,
    "baseValue": 504.3101223285179,
    "costProceed": -504.3101223285179,
    "averagePrice": 0,
    "pnlPrice": 1.7143025271186698,
    "balance": 1422.9396752556045,
    "baseBalance": 2112.280333273945,
    "inventory_cost": 1.69919737774718,
    "ABP": 1.722160298527064,
    "pnl": 0
  },
  {
    "time": 1724230500000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.6455,
    "amount": 266.27641805052315,
    "id": 662302,
    "cost": 438.1578459021358,
    "toCancel": true,
    "fees": 0.4381578459021358,
    "baseValue": 438.59600374803796,
    "costProceed": -438.59600374803796,
    "averagePrice": 0,
    "pnlPrice": 1.7063611053642411,
    "balance": 1689.2160933061277,
    "baseBalance": 1674.1224873718093,
    "inventory_cost": 1.692083378829045,
    "ABP": 1.7124369797518197,
    "pnl": 0
  },
  {
    "time": 1724242500000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.5884999999999998,
    "amount": 308.0022979795744,
    "id": 271453,
    "cost": 489.26165034055384,
    "toCancel": true,
    "fees": 0.48926165034055386,
    "baseValue": 489.7509119908944,
    "costProceed": -489.7509119908944,
    "averagePrice": 0,
    "pnlPrice": 1.692370762011925,
    "balance": 1997.2183912857022,
    "baseBalance": 1184.8608370312554,
    "inventory_cost": 1.6783191768183707,
    "ABP": 1.6963855724629062,
    "pnl": 0
  },
  {
    "time": 1724244600000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.5884999999999998,
    "amount": 182.50141488829084,
    "id": 340257,
    "cost": 289.90349755004996,
    "toCancel": true,
    "fees": 0.28990349755004996,
    "baseValue": 290.1934010476,
    "costProceed": -290.1934010476,
    "averagePrice": 0,
    "pnlPrice": 1.6855637860579764,
    "balance": 2179.719806173993,
    "baseBalance": 894.9573394812055,
    "inventory_cost": 1.6717633548795254,
    "ABP": 1.6887409485133258,
    "pnl": 0
  },
  {
    "time": 1724265600000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.62412121",
    "amount": 490.0132091549974,
    "id": 443430,
    "cost": 795.8408461687975,
    "fees": 0.7958408461687975,
    "baseValue": 795.0450053226288,
    "costProceed": 795.0450053226288,
    "averagePrice": 0,
    "pnlPrice": 1.6996378341376452,
    "balance": 1689.7065970189958,
    "baseBalance": 1690.798185650003,
    "inventory_cost": 1.6595446661830016,
    "ABP": 1.71243697975182,
    "pnl": 15.880636597172662
  },
  {
    "time": 1724288100000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.69717805",
    "amount": 173.24499757108543,
    "id": 853270,
    "cost": 294.0276071499495,
    "fees": 0.2940276071499495,
    "baseValue": 293.7335795427996,
    "costProceed": 293.7335795427996,
    "averagePrice": 0,
    "pnlPrice": 1.700125517721395,
    "balance": 1516.4615994479104,
    "baseBalance": 1984.8257927999525,
    "inventory_cost": 1.6520756946378563,
    "ABP": 1.7184491768667856,
    "pnl": 8.373861396075267
  },
  {
    "time": 1724324700000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "1.93031303",
    "amount": 1837.1341172823118,
    "id": 542615,
    "cost": 3546.2439244475945,
    "fees": 3.5462439244475945,
    "baseValue": 3542.697680523147,
    "costProceed": 3542.697680523147,
    "averagePrice": 0,
    "pnlPrice": 0,
    "balance": -320.67251783440133,
    "baseBalance": 5531.069717247547,
    "inventory_cost": -7.6,
    "ABP": 0,
    "pnl": 388.3245096237092
  },
  {
    "time": 1724368200000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.9914375000000002,
    "amount": 244.5383064078915,
    "id": 989279,
    "cost": 486.98275356716545,
    "toCancel": true,
    "fees": 0.48698275356716547,
    "baseValue": 487.46973632073264,
    "costProceed": -487.46973632073264,
    "averagePrice": 0,
    "pnlPrice": 1.9934289375000003,
    "balance": -76.13421142650984,
    "baseBalance": 5044.086963680381,
    "inventory_cost": 1.9914374999999944,
    "ABP": 1.9954263572882194,
    "pnl": 0
  },
  {
    "time": 1724384400000,
    "pair": "USDT-IO",
    "type": "sell",
    "rate": "2.00232100",
    "amount": 244.5383064078915,
    "id": 609954,
    "cost": 489.64418622495566,
    "fees": 0.48964418622495565,
    "baseValue": 489.1545420387307,
    "costProceed": 489.1545420387307,
    "averagePrice": null,
    "pnlPrice": 0,
    "balance": -2.6471757019154722,
    "baseBalance": 4996.370155325047,
    "inventory_cost": -7.6,
    "ABP": 0,
    "pnl": 1.6826312680938456
  },
  {
    "time": 1724398200000,
    "pair": "USDT-IO",
    "type": "buy",
    "rate": 1.999,
    "amount": 241.89113070597602,
    "id": 249723,
    "cost": 483.5403702812461,
    "toCancel": true,
    "fees": 0.48354037028124613,
    "baseValue": 484.02391065152733,
    "costProceed": -484.02391065152733,
    "averagePrice": 2.000999,
    "pnlPrice": 2.000999,
    "balance": 241.89113070597602,
    "baseBalance": 4506.725969100091,
    "inventory_cost": 1.9989999999999946,
    "ABP": 2.0030040050060074,
    "pnl": 0
  }
]

let previousCloseByTimeframe = {
  'S': 50, // Short timeframe initial close
  'M': 50, // Medium timeframe initial close
  'L': 50, // Long timeframe initial close
};



const Container = tw.div`flex flex-col p-6 max-w-6xl mx-auto`;
const ChartContainer = styled.div`
  ${tw`w-full mb-6 rounded-xl overflow-hidden relative`}
  height: 400px;
  background: linear-gradient(135deg, #0c141b 0%, #111921 90);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05) inset,
    0 0 20px rgba(0, 0, 0, 0.5);
  isolation: isolate;

  &::before, &::after {
    content: '';
    position: absolute;
    inset: -150%;
    background: conic-gradient(
      from 0deg,
      transparent 0deg,
      #00d4ff 72deg,
      #ff3366 144deg,
      #ffd700 216deg,
      #2fff85 288deg,
      transparent 360deg
    );
    animation: rotate 15s linear infinite;
    z-index: -1;
  }

  &::after {
    filter: blur(20px);
    opacity: 0.18;
  }

  @keyframes rotate {
    100% { transform: rotate(360deg); }
  }
`;

const ChartWrapper = styled.div`
  position: relative;
  height: 100%;
  background: rgba(13, 17, 23, 0.95);
  backdrop-filter: blur(50px);
  border-radius: 12px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: 
      radial-gradient(circle at top left, rgba(255, 255, 255, 0.04) 0%, transparent 20%),
      radial-gradient(circle at bottom right, rgba(255, 255, 255, 0.04) 0%, transparent 80%);
    z-index: 1;
    pointer-events: none;
  }

`;




const FeaturesContainer = tw.div`grid grid-cols-2 gap-4`;
const FeatureItem = styled.div(({ selected }) => [
  tw`p-4 rounded-lg cursor-pointer transition-all duration-200 ease-in-out`,
  selected ? tw`bg-gray-700` : tw`bg-gray-800 hover:bg-gray-700`,
]);
const FeatureTitle = tw.h3`text-white font-bold text-sm mb-2`;
const FeatureDescription = tw.p`text-gray-300 text-sm`;


const ChartAndFeatures = ({translations}) => {
  const features = translations.charts
  const chartRef = useRef(null);
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = createChart(chartRef.current, {
      width: chartRef.current.clientWidth,
      height: 400,
      layout: {
        background: { type: 'solid', color: 'transparent' },
        textColor: '#e0e3eb',
      },
      grid: {
        vertLines: { color: 'rgba(42, 46, 57, 0.0)' },
        horzLines: { color: 'rgba(42, 46, 57, 0.0)' },
      },
      crosshair: {
        mode: 2,
        vertLine: { color: 'rgba(0, 255, 255, 0.4)', width: 1, style: 3 },
        horzLine: { color: 'rgba(0, 255, 255, 0.4)', width: 1, style: 3 },
      },
      watermark: {
        visible: true,
        fontSize: 40,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(255, 255, 255, 0.03)',
        text: selectedFeature === features[0] ? 'GUNBOT' : '' ,
      },
      timeScale: {
        visible: false,  // Hides the time scale
      },
      rightPriceScale: {
        visible: false,
      },
      leftPriceScale: {
        visible: false,
      },

    });

    if (selectedFeature.id === 1) {
      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderUpColor: '#26a69a',
        borderDownColor: '#ef5350',
        wickUpColor: 'rgba(38, 166, 154, 0.8)',
        wickDownColor: 'rgba(239, 83, 80, 0.8)',
      });

      const initialData = demoCandles.slice(0, 100).map(candle => ({
        time: Math.floor(candle.t / 1000),  // converting timestamp to seconds
        open: candle.o,
        high: candle.h,
        low: candle.l,
        close: candle.c,
      }));

      candlestickSeries.setData(initialData);

      let currentIndex = 100;
      const interval = setInterval(() => {
        if (currentIndex < demoCandles.length) {
          const newCandle = {
            time: Math.floor(demoCandles[currentIndex].t / 1000),
            open: demoCandles[currentIndex].o,
            high: demoCandles[currentIndex].h,
            low: demoCandles[currentIndex].l,
            close: demoCandles[currentIndex].c,
          };
          candlestickSeries.update(newCandle);

          // Add Buy and Sell Markers
          const visibleOrders = orders.filter(order => order.time / 1000 <= demoCandles[currentIndex].t / 1000);
          const markers = visibleOrders.map(order => ({
            time: Math.floor(order.time / 1000), // convert timestamp to seconds
            position: order.type === 'buy' ? 'belowBar' : 'aboveBar',
            color: order.type === 'buy' ? '#4bffb5' : '#ff4976',
            shape: order.type === 'buy' ? 'arrowUp' : 'arrowDown',
            text: `${order.type.toUpperCase()}`,
          }));

          candlestickSeries.setMarkers(markers);



          currentIndex++;
          chart.timeScale().scrollToPosition(0, false);
        } else {
          clearInterval(interval);
        }
      }, 330);



      return () => {
        clearInterval(interval);
        chart.remove();
      };
    }


    if (selectedFeature.id === 2) {
      const timeframes = [
        { id: 'S', interval: 1, label: 'Short' },   // 1-minute intervals
        { id: 'M', interval: 1, label: 'Medium' },  // Forcing 1-minute intervals for uniformity
        { id: 'L', interval: 1, label: 'Long' }     // Forcing 1-minute intervals for uniformity
      ];

      const series = timeframes.map((tf, index) => {
        const baseOpacity = 1;
        const decrement = 0.4;
        const opacity = Math.max(baseOpacity - (index * decrement), 0.1);

        return chart.addCandlestickSeries({
          upColor: `rgba(38, 166, 154, ${opacity})`,
          downColor: `rgba(239, 83, 80, ${opacity})`,
          borderUpColor: `rgba(38, 166, 154, ${opacity})`,
          borderDownColor: `rgba(239, 83, 80, ${opacity})`,
          wickUpColor: `rgba(38, 166, 154, ${opacity})`,
          wickDownColor: `rgba(239, 83, 80, ${opacity})`,
          priceScaleId: 'right'
        });
      });

      // Generate and set initial data
      timeframes.forEach((tf, index) => {
        const data = generateDummyOHLCData(100, 1); // Use interval of 1 for all
        series[index].setData(data);
        previousCloseByTimeframe[tf.id] = data[data.length - 1].close; // Store the last close price for continuity
      });

      let currentIndex = 100; // Tracks the candle index for the base timeframe (1 minute)
      const interval = setInterval(() => {
        const baseTime = currentIndex; // Base time for all timeframes

        // Generate a candle for each series at every iteration (so there is always a candle for each bar)
        timeframes.forEach((tf, index) => {
          const newDataPoint = generateNewOHLC(baseTime, tf.id); // Generate new data for each series
          series[index].update(newDataPoint);
        });

        currentIndex++;
        chart.timeScale().scrollToPosition(0, false); // Scroll to the latest candle
      }, 1000);

      return () => {
        clearInterval(interval);
        chart.remove();
      };
    }

  }, [selectedFeature])


  const generateDummyOHLCData = (points) => {
    const data = [];
    let basePrice = 50; // Starting price
    let volatility = 2; // Controls the size of price movements

    for (let i = 0; i < points; i++) {
      // Simulate some trends or price movements
      const randomDirection = Math.random() > 0.5 ? 1 : -1;
      const open = basePrice + (Math.random() * volatility * randomDirection);

      // Ensure the close price is close to the open with some random movement
      const close = open + (Math.random() * volatility * randomDirection);

      // High and low are based on open/close with small variations to simulate realistic ranges
      const high = Math.max(open, close) + (Math.random() * volatility);
      const low = Math.min(open, close) - (Math.random() * volatility);

      // Push the OHLC data point
      data.push({
        time: i,
        open: open,
        high: high,
        low: low,
        close: close,
      });

      // Set the basePrice for the next iteration to the current close price
      basePrice = close;
    }

    return data;
  };


  const generateNewOHLC = (baseTime, timeframe) => {
    const volatility = 2;
    const randomDirection = Math.random() > 0.5 ? 1 : -1;

    // Open price is based on the previous close price for the current timeframe
    const open = previousCloseByTimeframe[timeframe] + (Math.random() * volatility * randomDirection);

    // Close price moves in a direction relative to the open price
    const close = open + (Math.random() * volatility * randomDirection);

    // High and low calculated realistically
    const high = Math.max(open, close) + (Math.random() * volatility);
    const low = Math.min(open, close) - (Math.random() * volatility);

    // Update the previous close for this timeframe
    previousCloseByTimeframe[timeframe] = close;

    // Return the OHLC data
    return {
      time: baseTime, // Use the base time which is aligned for all timeframes
      open: open,
      high: high,
      low: low,
      close: close,
    };
  };


  return (
    <Container>
      <ChartContainer>
        <ChartWrapper>
          <div ref={chartRef} style={{ height: '100%', width: '100%' }} />
        </ChartWrapper>
      </ChartContainer>
      <FeaturesContainer>
        {features.map((feature) => (
          <FeatureItem
            key={feature.id}
            selected={selectedFeature?.id === feature.id}
            onClick={() => setSelectedFeature(feature)}
          >
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureItem>
        ))}
      </FeaturesContainer>
    </Container>
  );
};

export default ChartAndFeatures;
