import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeadingH2, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/spotGrid.png";

const Container = tw.div`relative -mt-48`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-32 md:ml-12 lg:ml-0 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const TextContent = tw.div`lg:py-8 text-left`;

const Subheading = tw(SubheadingBase)`text-left text-gray-800`;
const Heading = tw(
  SectionHeadingH2
)`mt-4 font-black text-left  text-left leading-tight text-gray-800`;
const Description = tw.p`mt-8 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
  translations
}) => {
  const subheading = translations.grid.subheading;
  const heading = translations.grid.heading;
  const description = translations.grid.description;
  const imageSrc = TeamIllustrationSrc;
  const imageRounded = true;
  const imageBorder = false;
  const imageShadow = true;
  const textOnLeft = true;
  const features = null;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} width="640px" height="849px" loading="lazy" alt={translations.hero.imageAlt} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent className="display-linebreak">
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
