import React from "react";
import tw from "twin.macro";
import { Link, useLocation } from 'react-router-dom';
import LanguageSelector from "components/custom/LanguageSelector";
import Paths from "_content/Paths.js";

import {
  NavLinks
} from "components/headers/light.js";

const PrimaryLink = tw(Link)`lg:mx-0
px-6 py-3 rounded bg-primary-500 text-gray-100
hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline font-semibold
border-b-0 shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;
const NavLink = tw(Link)`text-lg my-2 lg:text-sm lg:mx-5 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100 `;
const NavLinkA = tw.a`text-lg my-2 lg:text-sm lg:mx-5 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100 `;

const NavigationWrapper = () => {
  const location = useLocation()

  const isNarrowWindow = window.innerWidth >= 800 && window.innerWidth <= 1350;

  const isSpanish = location.pathname.includes('/es/')

  return (
    <NavLinks key={1} style={{ zIndex: '1000000', marginTop: '-0.5rem' }}>
      {isSpanish ?
        <>
          <NavLink to="/es/como-funciona-gunbot/" title="Aprenda cómo funciona Gunbot">Como funciona Gunbot</NavLink>
          <NavLink to="/es/caracteristicas/" title="Todas las características de Gunbot">Características</NavLink>
          <NavLink to="/es/bot-de-trading-defi/" title="Use Gunbot para tradear en DeFi  / exchanges decentralizados">Bot de trading DeFi </NavLink>
          <NavLink to="/es/rendimiento/" title="Estadísticas de rendimiento de Gunbot">Rendimiento</NavLink>
          <NavLink to="/es/descargas/" title="Descargue versiones de software estables o beta">Descargar</NavLink>
          <LanguageSelector paths={Paths} />
          <PrimaryLink to="/es/planes/" title="Descubra los Planes y precios de Gunbot">Planes y precios</PrimaryLink>
        </>
        :
        <>
          <NavLink to="/how-gunbot-works/" title="How">How</NavLink>
          <NavLink to="/features/" title="Gunbot feature overview">Features</NavLink>
          <NavLink to="/defi-trading-bot/" title="Use Gunbot as trading bot on defi / decentralized exchanges">DeFi bot</NavLink>
          {!isNarrowWindow &&<NavLink to="/performance/" title="Monitor Gunbot performance">Performance</NavLink>}
          <NavLink to="/devcommunity/" title="Explore the Gunbot Developer Community">Dev Community</NavLink>
          <NavLinkA href="https://www.gunbot.com/blog/" title="Stay up to date on the Gunbot blog">Blog</NavLinkA>
          <NavLinkA href="https://www.gunbot.com/support/" title="Software documentation">Docs & Guides</NavLinkA>
          {!isNarrowWindow && <NavLink to="/downloads/" title="Download stable or beta software releases">Download</NavLink>}
          <LanguageSelector paths={Paths} />
          <PrimaryLink to="/plans/" title="Plans & pricing overview for Gunbot">Plans & pricing</PrimaryLink>

        </>}
    </NavLinks>
  )
};

export default NavigationWrapper;

