import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import tw from "twin.macro";
import styled from "styled-components";
import usFlag from 'images/us.png'; // Path to the US flag image
import esFlag from 'images/es.png'; // Path to the Spain flag image
import { css } from "styled-components/macro"; //eslint-disable-line

// Define your styles using tw
const SelectorContainer = tw.div`inline-block text-left relative lg:mr-4 mb-4`; // Added relative here
const Button = tw.button`inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1 mt-1 bg-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-black`;
const Dropdown = tw.div`origin-top-right absolute right-0 mt-1 w-32 rounded-md shadow-lg bg-gray-300`; // Adjust origin and margins as needed
const MenuItem = tw.button`text-gray-700 block w-full px-4 py-2 text-sm hover:bg-gray-400`;
const MenuItemContent = tw.div`flex items-center`;

function FlagIcon({ countryCode = "" }) {
    const flagImages = {
        en: usFlag,
        es: esFlag,
    };

    const imageSrc = flagImages[countryCode.toLowerCase()] || ""; // Fallback if needed

    return imageSrc ? <img src={imageSrc} alt={`${countryCode} flag`} style={{ width: '14px', height: 'auto' }} /> : <span>🏳️</span>;
}

const LanguageSelector = ({ paths }) => {
    const location = useLocation();
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [languageUrls, setLanguageUrls] = useState({});

    function generateLanguageUrls(currentPath) {
        let languageUrls = {};

        for (let group of paths) {
            if (group.includes(currentPath)) {
                for (let path of group) {
                    // Extracting the language code from the path
                    const matches = path.match(/^\/(es|fr)?\//);
                    const langCode = matches && matches[1] ? matches[1] : 'en';

                    // Constructing the URL with modifications here
                    let url = "";
                    if (langCode !== 'en') {
                        url += `/${langCode}`;
                    }

                    let pathSuffix = path.replace(/^\/(es|fr)?\/?/, '').replace(/\/$/, ''); // Remove leading language code and trailing slash
                    if (pathSuffix) {
                        url += `/${pathSuffix}`;
                    }

                    // Add trailing slash if not present
                    if (!url.endsWith('/')) {
                        url += '/';
                    }

                    languageUrls[langCode] = url;
                }
                break; // Found the current path, no need to check other groups
            }
            else {
                languageUrls['en'] = currentPath;
            }
        }

        return languageUrls;
    }


    useEffect(() => {
        const relevantUrls = generateLanguageUrls(location.pathname);
        setLanguageUrls(relevantUrls);
    }, [location]);

    const languageStrings = {
        en: 'English',
        es: 'Spanish'
    }

    const languages = Object.keys(languageUrls).map(key => ({
        key,
        name: languageStrings[key],
        url: languageUrls[key]
    }));
    const [selectedLanguage, setSelectedLanguage] = useState({ key: location.pathname.includes('/es/') ? 'es' : 'en' });

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        history.push(language.url);
    };

    return (
        <SelectorContainer>
            <Button onClick={() => setIsOpen(!isOpen)} id="language-selector">
                <FlagIcon countryCode={selectedLanguage.key} />
                <svg tw="-mr-1 ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#1a1a1a" aria-hidden="true">
                    <path fillRule="evenodd" d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z" clipRule="evenodd" />
                </svg>
            </Button>
            {isOpen && (
                <Dropdown role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                    <div tw="py-1" role="none">
                        {languages.map((language) => (
                            <MenuItem
                                key={language.key}
                                role="menuitem"
                                tabIndex="-1"
                                onClick={() => handleLanguageChange(language)}
                            >
                                <MenuItemContent>
                                    <FlagIcon countryCode={language.key} />
                                    <span style={{ marginLeft: '8px' }}>{language.name}</span>
                                </MenuItemContent>
                            </MenuItem>
                        ))}
                        {Object.keys(languageUrls).length < 2 &&
                            <MenuItem>
                                This page is only available in English.
                            </MenuItem>}
                    </div>
                </Dropdown>
            )}
        </SelectorContainer>
    );
};

export default LanguageSelector;
