import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

const Section = styled.section`
  ${tw`py-8 mb-32`}
`;

const Container = styled.div`
  ${tw`max-w-4xl mx-auto px-4`}
`;

const Heading = styled.h2`
  ${tw`text-3xl font-bold mb-4 text-gray-800`}
`;

const Intro = styled.p`
  ${tw`text-lg mb-6 text-gray-600`}
`;

const LinkList = styled.ul`
  ${tw`space-y-4`}
`;

const LinkItem = styled.li`
  ${tw`bg-white rounded-lg shadow-md transition-transform duration-300 hover:transform hover:scale-105`}
`;

const StyledLink = styled.a`
  ${tw`block p-4 text-gray-800 hover:text-primary-600`}
`;

const RelatedDocsSection = ({ title, introText, articles }) => {
  return (
    <Section>
      <Container>
        <Heading>{title}</Heading>
        <Intro>{introText}</Intro>
        <nav aria-label="Related topics">
          <LinkList>
            {articles.map((article, index) => (
              <LinkItem key={index}>
                <StyledLink 
                  href={article.url} 
                  target="_self" 
                  rel="noopener noreferrer"
                >
                  {article.title}
                </StyledLink>
              </LinkItem>
            ))}
          </LinkList>
        </nav>
      </Container>
    </Section>
  );
};

export default RelatedDocsSection;