import React, { useState, useEffect, Suspense } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import Navigation from '_content/Navigation.js';
import { Link } from 'react-router-dom';
import { sample } from 'lodash-es';
import Showcase from 'components/custom/Showcase.js';
import ChartAndFeatures from 'components/custom/ChartAndFeatures.js';

import HeaderBase, {
    LogoLink as LogoLinkBase,
} from 'components/headers/light.js';

import {
    Container as ContainerBase,
    ContentWithVerticalPadding,
    Content2Xl,
} from 'components/misc/Layouts.js';
import { SectionHeading } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import logoImageSrc from 'images/logo-light.svg';
import CustomersLogoStripImage from 'images/partners-logo-strip.png';


const isMobile = window.innerWidth <= 768;

const PrimaryBackgroundContainer = styled.div`
${tw`-mx-8 px-8 pt-0 mt-0 text-gray-100`}
background-color: #1a1a1a;
background-image:
  radial-gradient(circle at 60% 70%, rgba(15, 35, 55, 0.95) 0%, rgba(25, 45, 65, 0.90) 50%, rgba(10, 30, 50, 0) 100%, transparent 100%),
  radial-gradient(circle at 50% 50%, rgba(20, 40, 60, 0.95) 0%, transparent 100%);
`;

const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 -mt-4`;

const Container = tw(ContainerBase)`-mt-20 lg:-mt-8`;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left lg:order-first order-last`;
const IllustrationColumn = tw(Column)`-mt-12 md:mt-0 md:mb-0 mb-6 md:ml-16 md:w-3/4 w-full md:order-last order-first hidden md:block`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl text-left leading-tight md:text-5xl text-2xl`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 text-left lg:text-base mx-auto lg:mx-0`;

const Blogpost = tw.a`border-l-2 border-blue-600 pl-2 font-medium text-sm text-left`;

const ShowcaseContainer = tw(Showcase)``;
const ChartContainer = tw(ChartAndFeatures)``;
const Actions = styled.div`
  ${tw`mb-8 lg:mb-0 mt-4`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 px-2 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-400 text-white hover:bg-primary-500`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;
const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-8`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;


const RecentPosts = ({ translations }) => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        if (isMobile) {
            return
        }

        fetch('https://www.gunbot.com/blog/rss.xml')
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, 'text/xml');
                const items = xmlDoc.querySelectorAll('item');

                const recentPosts = Array.from(items).slice(0, 3).map(item => ({
                    title: item.querySelector('title').textContent,
                    link: item.querySelector('link').textContent,
                    pubDate: item.querySelector('pubDate').textContent
                }));

                setPosts(recentPosts);
            })
            .catch(error => {
                console.error('Error fetching RSS feed:', error);
            });
    }, []);

    return (
        <div style={{ marginTop: '2.5rem' }} tw={"hidden lg:block"}>
            <strong style={{ color: '#fff', textDecoration: 'none' }}>{translations.hero.recentNewsAndArticlesText}</strong>
            {posts.map(post => (
                <div key={post.link}>
                    <Blogpost href={post.link + '/'} title={post.title} style={{ color: '#fff', textDecoration: 'none' }}
                        target="_blank">{post.title}</Blogpost>
                </div>
            ))}
        </div>
    );
};

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-red-900 font-medium text-sm text-left flex`;

const variation2 = sample([true, false, false]);


const OptimizedModule = ({ translations }) => {
    const { hero } = translations;
    const logoLink = (
        <LogoLink to="/">
            <img src={logoImageSrc} alt="Gunbot logo" width="40px" height="40px" style={{ width: "40px", height: "40px" }} />
            Gunbot
        </LogoLink>
    );

    return (
        <PrimaryBackgroundContainer>
            <Content2Xl>
                <Header logoLink={logoLink} links={<Navigation />} />
                <Container>
                    <ContentWithVerticalPadding>
                        <Row>
                            <TextColumn>
                                <Notification>{hero.notification}</Notification>
                                <Heading>{hero.heading}</Heading>
                                <Description>{hero.description}</Description>
                                <br />
                                <Actions>
                                    <Link to={hero.plansLink} title="Compare plans" className="action primaryAction">
                                        {hero.comparePlansButton}
                                    </Link>
                                    <Link to={hero.performanceLink} title={hero.checkPerformanceButton} className="action secondaryAction">
                                        {hero.checkPerformanceButton}
                                    </Link>
                                </Actions>
                                <CustomersLogoStrip>
                                    <p>{hero.officialPartnersText}</p>
                                    <img src={CustomersLogoStripImage} alt={hero.partnersAltText} style={{ width: "624px" }} />
                                </CustomersLogoStrip>
                                {!isMobile && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <RecentPosts translations={translations} />
                                    </Suspense>
                                )}
                            </TextColumn>
                            {!isMobile && (
                                <IllustrationColumn style={{ marginTop: '-80px' }}>
                                    {/*<ShowcaseContainer style={{ width: '752px' }} translations={translations}/> */}
                                    <ChartContainer style={{ width: '752px' }} translations={translations} />
                                </IllustrationColumn>
                            )}
                        </Row>
                    </ContentWithVerticalPadding>
                </Container>
            </Content2Xl>
        </PrimaryBackgroundContainer>
    );
};
export default React.memo(OptimizedModule);
