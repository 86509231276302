import "components/custom/showcaseStyles.css";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import styled from "styled-components";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import headerImage from "images/showcase_1.png";
import headerImage2 from "images/showcase_2.png";
import headerImage3 from "images/showcase_3.png";
import { SectionDescription } from "components/misc/Typography.js";
import { SectionHeadingH2 } from "components/misc/Headings.js";

const Heading = tw(SectionHeadingH2)`-mt-44 w-full text-center leading-tight text-xl`;
const ActiveStep = tw(SectionDescription)`mt-4 text-gray-500 lg:text-sm text-center mx-auto lg:mx-0 leading-tight`;
const InactiveStep = tw(SectionDescription)`mt-4 text-gray-1000 lg:text-sm text-center mx-auto lg:mx-0 leading-tight`;
const ActiveStepDescription = tw(SectionDescription)`text-gray-200 font-bold text-lg mt-0 text-center leading-tight`;
const InactiveStepDescription = tw(SectionDescription)`text-gray-1000 font-bold text-lg mt-0 text-center leading-tight`;
const ShowCaseDescription = tw(SectionDescription)`text-gray-200 w-full text-center text-xl mt-0 mb-24 max-w-full`;
const images = [headerImage, headerImage2, headerImage3];

const ImageComponent = React.memo(({ src, alt, width, height }) => (
  <img
    src={src}
    alt={alt}
    width={width}
    height={height}
    loading="lazy"
    decoding="async"
  />
));

export default function App({ translations }) {
  const tabs = useMemo(() => [
    {
      step: translations.showcase.step3.step,
      label: translations.showcase.step3.label,
      content: (
        <>
          <ImageComponent
            src={images[0]}
            alt={translations.showcase.step3.alt1}
            width="752"
            height="447"
          />
          <Heading style={{ fontSize: '1.5rem', textShadow: '4px 4px 5px rgba(0, 0, 0, 0.35),-5px 5px 5px rgba(0, 0, 0, 0.35),0px -7px 9px rgba(0, 0, 0, 0.35),-12px -12px 12px rgba(0, 0, 0, 0.35),-20px 11px 21px rgba(0, 0, 0, 0.35)' }}>
            {translations.showcase.step3.heading}
          </Heading>
          <ShowCaseDescription style={{ textShadow: '4px 4px 5px rgba(0, 0, 0, 0.55),-5px 5px 5px rgba(0, 0, 0, 0.55),0px -7px 9px rgba(0, 0, 0, 0.55),-12px -12px 12px rgba(0, 0, 0, 0.55),-20px 11px 21px rgba(0, 0, 0, 0.55)', fontSize: '0.9rem' }}>
            {translations.showcase.step3.description}
          </ShowCaseDescription>
        </>
      ),
    },
    {
      step: translations.showcase.step2.step,
      label: translations.showcase.step2.label,
      content: (
        <>
          <ImageComponent
            src={images[1]}
            alt={translations.showcase.step2.alt2}
            width="752"
            height="447"
          />
          <Heading style={{ fontSize: '1.5rem', textShadow: '4px 4px 5px rgba(0, 0, 0, 0.35),-5px 5px 5px rgba(0, 0, 0, 0.35),0px -7px 9px rgba(0, 0, 0, 0.35),-12px -12px 12px rgba(0, 0, 0, 0.35),-20px 11px 21px rgba(0, 0, 0, 0.35)' }}>
            {translations.showcase.step2.heading}
          </Heading>
          <ShowCaseDescription style={{ textShadow: '4px 4px 5px rgba(0, 0, 0, 0.55),-5px 5px 5px rgba(0, 0, 0, 0.55),0px -7px 9px rgba(0, 0, 0, 0.55),-12px -12px 12px rgba(0, 0, 0, 0.55),-20px 11px 21px rgba(0, 0, 0, 0.55)', fontSize: '0.9rem' }}>
            {translations.showcase.step2.description}
          </ShowCaseDescription>
        </>
      ),
    },
    {
      step: translations.showcase.step1.step,
      label: translations.showcase.step1.label,
      content: (
        <>
          <ImageComponent
            src={images[2]}
            alt={translations.showcase.step1.alt3}
            width="752"
            height="447"
          />
          <Heading style={{ fontSize: '1.5rem', textShadow: '4px 4px 5px rgba(0, 0, 0, 0.35),-5px 5px 5px rgba(0, 0, 0, 0.35),0px -7px 9px rgba(0, 0, 0, 0.35),-12px -12px 12px rgba(0, 0, 0, 0.35),-20px 11px 21px rgba(0, 0, 0, 0.35)' }}>
            {translations.showcase.step1.heading}
          </Heading>
          <ShowCaseDescription style={{ textShadow: '4px 4px 5px rgba(0, 0, 0, 0.55),-5px 5px 5px rgba(0, 0, 0, 0.55),0px -7px 9px rgba(0, 0, 0, 0.55),-12px -12px 12px rgba(0, 0, 0, 0.55),-20px 11px 21px rgba(0, 0, 0, 0.55)', fontSize: '0.9rem' }}>
            {translations.showcase.step1.description}
          </ShowCaseDescription>
        </>
      ),
    },
  ], [translations]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    const img = new Image();
    img.src = images[0];
  }, []);

  useEffect(() => {
    setSelectedTab(tabs[0]);
  }, [tabs]);

  const handleTabClick = useCallback((item) => {
    setSelectedTab(item);
  }, []);

  return (
    <div className="showcaseWindow">
      <link rel="preload" href={images[0]} as="image" />
      
      <div className="ShowcaseMain">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={selectedTab.label}
            animate={{ opacity: 1, y: 10 }}
            initial={{ opacity: 1, y: 20 }}
            exit={{ opacity: 1, y: -20 }}
            transition={{
              delay: 0.05,
              x: { type: "spring", stiffness: 30 },
              default: { duration: 0.15 },
            }}
          >
            {selectedTab.content}
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="ShowcaseNav">
        <ul
          style={{
            listStyle: 'none',
            padding: '0',
            paddingTop: '4px',
            margin: '0',
            width: '100%',
            height: '80px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {tabs.map((item, index) => (
            <li
              key={index}
              onClick={() => handleTabClick(item)}
              style={{
                listStyle: 'none',
                padding: '0',
                margin: '0',
                width: '100%',
                borderRadius: '5px',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                padding: '10px 15px',
                position: 'relative',
                cursor: 'pointer',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1',
                minWidth: '0',
                userSelect: 'none'
              }}
            >
              <motion.div
                whileHover={{
                  scale: 1.15,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
                className={'w-full'}
              >
                {selectedTab.label === item.label ? (
                  <>
                    <ActiveStep>{item.step}</ActiveStep>
                    <ActiveStepDescription>{item.label}</ActiveStepDescription>
                  </>
                ) : (
                  <>
                    <InactiveStep>{item.step}</InactiveStep>
                    <InactiveStepDescription>{item.label}</InactiveStepDescription>
                  </>
                )}
              </motion.div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}