import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { SectionHeadingH2, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Link } from 'react-router-dom';

const Container = tw.div`relative -mt-32 w-full`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-48 md:py-24`}
`;

const Subheading = tw(SubheadingBase)`mb-4 text-gray-800`;
const Heading = tw(SectionHeadingH2)`w-full text-gray-800`;
const Description = tw(SectionDescription)`w-full text-left`;

const VerticalSpacer = tw.div`mt-10 w-full`;
const LinkA = tw.a`underline text-secondary-100 hover:text-black`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled(motion.div)`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-left h-full mx-4 px-2 py-8`}
  background: linear-gradient(135deg, #f8f9fa 0%, #e2e6ed 100%);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.18);

  &:hover {
    box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2 `}
  }

  .title {
    ${tw``}
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.75rem;
  }

  .description {
    ${tw`mt-1 sm:mt-4`}
    font-size: 0.875rem;
    color: #555;
    flex-grow: 1;
  }
`;

export default ({
  translations
}) => {

  const defaultCards = translations.why.cards;

  return (
    <Container>
      <ThreeColumnContainer>
        {translations.why.subheading && <Subheading>{translations.why.subheading}</Subheading>}
        <Heading>{translations.why.heading}</Heading>
        {translations.why.description && <Description className="display-linebreak" style={{"maxWidth" : "100%"}}>{translations.why.description}</Description>}
        <VerticalSpacer />
        {defaultCards.map((card, i) => (
          <Column key={i}>
            <Card
              whileHover={{ y: -5, boxShadow: '0 10px 30px -15px rgba(0, 0, 0, 0.2)' }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: i * 0.1 }}
            >
              <span className="textContainer">
                <h3 className="title" tw="text-gray-800 text-left">{card.title}</h3>
                <p className="description display-linebreak" tw="text-left">
                  {card.description}
                </p>
              </span>
            </Card>
            <VerticalSpacer />
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
