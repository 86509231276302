import React, { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeadingH2, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import vs2015 from "react-syntax-highlighter/dist/esm/styles/hljs/vs2015";

SyntaxHighlighter.registerLanguage("javascript", js);

const isMobile = window.innerWidth <= 768;

const Container = tw.div`relative -mt-12 min-h-full`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const CodeColumn = tw(Column)`md:w-8/12 flex-shrink-0 relative md:-mt-8 md:-ml-12 md:mr-12 text-xs`;
const TextColumn = styled(Column)(props => [
  tw`md:w-4/12 mt-16 md:mt-0 self-start whitespace-pre-line`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-gray-800`;
const Heading = tw(SectionHeadingH2)`mt-4 font-black text-left leading-tight text-gray-800`;
const Description = tw.p`mt-8 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mb-8`;
const ActionButton = tw.a`min-w-full text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-8 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline bg-primary-400 text-gray-100 shadow-lg hocus:bg-primary-500 hocus:text-gray-200 block`;

export default ({
  translations
}) => {
  const { subheading, heading, description, actionButtonText, actionButtonURL } = translations.gunbotAI;

  const workerRef = useRef(null);
  const example = strats[0];

  useEffect(() => {
    if (isMobile) {
      return;
    }
    // Initialize the web worker
    workerRef.current = new Worker(URL.createObjectURL(new Blob([highlighterWorkerScript])));
    return () => {
      // Terminate the web worker when the component is unmounted
      workerRef.current.terminate();
    };
  }, []);


  useEffect(() => {
    if (isMobile) {
      return;
    }
    // Call the web worker to highlight the code
    workerRef.current.postMessage(example);
    workerRef.current.onmessage = event => {
      const highlightedCode = event.data;
      setHighlightedCode(highlightedCode);
    };
  }, [example]);

  const [highlightedCode, setHighlightedCode] = useState("");

  const textOnLeft = false;

  return (
    <Container>
      <TwoColumn>
        <CodeColumn style={!isMobile ? { paddingTop: "48px" } : {}}>
          {!isMobile && <SyntaxHighlighter language="javascript" style={vs2015}>
            {highlightedCode}
          </SyntaxHighlighter>}
        </CodeColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {!isMobile && (
              <ActionButton rel="noopener noreferrer" href={actionButtonURL} title={actionButtonText} target="_self">
                {actionButtonText}
              </ActionButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

const highlighterWorkerScript = `
  // Web worker script for syntax highlighting
  self.onmessage = event => {
    const codeString = event.data;
    // Highlight the code using the desired syntax highlighter library and style
    // For example:
    // import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
    // import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';
    // const highlightedCode = <SyntaxHighlighter language="javascript" style={vs2015}>{codeString}</SyntaxHighlighter>;
    // self.postMessage(highlightedCode);

    // In this example, we will just return the codeString as is (no highlighting)
    self.postMessage(codeString);
  };
`;


const strats = [
  `// User prompt:
// Make a short term wick hunter strategy using a self invented TA indicator perfectly suited to finding wicks,  it should buy predicted wicks down, and sell predicted wicks up. use limit orders.  
// Don't buy when there is a bag or an open order, don't sell when there is no bag or there are open orders. Use TRADING_LIMIT for buy amount

// Gunbot AI:

// initialize customStratStore within pairLedger object
gb.data.pairLedger.customStratStore = gb.data.pairLedger.customStratStore || {};

// define custom TA indicator to find wicks
function findWicks() {
  // calculate the difference between high and low of each candle
  const candleRange = gb.data.candlesHigh.map((high, i) => high - gb.data.candlesLow[i]);
  // calculate the average range of the last 10 candles
  const avgRange = candleRange.slice(-10).reduce((acc, val) => acc + val, 0) / 10;
  // calculate the average body size of the last 10 candles
  const avgBody = gb.data.candlesClose.slice(-10).reduce((acc, val, i) => acc + Math.abs(val - gb.data.candlesOpen.slice(-10)[i]), 0) / 10;
  // calculate the predicted wick size as the difference between the average range and average body size
  const predictedWick = avgRange - avgBody;
  // return the predicted wick size
  return predictedWick;
}

// forced wait time reduces risk of double orders
function checkTime() {
  return !gb.data.pairLedger.customStratStore.timeCheck || typeof gb.data.pairLedger.customStratStore.timeCheck !== "number" 
    ? (gb.data.pairLedger.customStratStore.timeCheck = Date.now(), false) 
    : (Date.now() - gb.data.pairLedger.customStratStore.timeCheck > 8000);
}
const enoughTimePassed = checkTime();

// set timestamp for checkTime in next round
const setTimestamp = () => gb.data.pairLedger.customStratStore.timeCheck = Date.now();

if (enoughTimePassed) {
  // calculate the predicted wick size
  const predictedWick = findWicks();
  // calculate the buy and sell prices based on the predicted wick size
  const buyPrice = gb.data.bid - predictedWick;
  const sellPrice = gb.data.ask + predictedWick;
  // calculate the buy amount based on TRADING_LIMIT
  const buyAmount = parseFloat(gb.data.pairLedger.whatstrat.TRADING_LIMIT) / buyPrice;
  // check if there is a bag or open orders
  const hasBag = gb.data.gotBag;
  const hasOpenOrders = gb.data.openOrders.length > 0;
  // check if buy or sell conditions are met
  const buyConditions = predictedWick > 0 && !hasBag && !hasOpenOrders;
  const sellConditions = predictedWick > 0 && hasBag && !hasOpenOrders;
  // fire orders when conditions are met
  if (buyConditions) {
    gb.method.buyLimit(buyAmount, buyPrice, gb.data.pairName);
    setTimestamp();
  } else if (sellConditions) {
    gb.method.sellLimit(gb.data.quoteBalance, sellPrice, gb.data.pairName);
    setTimestamp();
  }
}
      
// Code is machine generated, review it and run in simulator mode first
`
]