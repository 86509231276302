import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionDescription } from "components/misc/Typography.js";
import { SectionHeadingH2, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
import TooltipTrigger from 'react-popper-tooltip';
import { Link } from 'react-router-dom';
import 'react-popper-tooltip/dist/styles.css';
import Content from "_content/Content.js";
import { isNil } from 'lodash-es'

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:pb-6 md:pb-8 lg:pb-12 xl:pb-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeadingH2)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center mb-2`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium px-8 md:px-0`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-6 px-4 sm:px-10 lg:px-6 lg:py-6 xl:p-6 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const DurationTeaser = styled.div`
  ${tw`w-4/12 bg-gray-400 rounded-lg shadow-sm py-4 px-4 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised `}
`;


const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-0 py-0 rounded leading-none md:flex-row flex-col mb-8`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-xs sm:text-base text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-400! text-gray-100!`}
  }
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`text-25xl font-black text-gray-800 tracking-wider self-start`}
  }
  .nameUpgrade {
    ${tw`text-4xl font-black text-gray-800 tracking-wider mr-3 min-h-28`}
  }
  .nameSmaller {
    ${tw`text-2xl font-black text-gray-800 tracking-wider`}
  }
  .nameSmaller2 {
    ${tw`text-3xl font-black text-gray-800 tracking-wider`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .featuredTextPromo {
    ${tw`text-xxs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .featuredTextDummy {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 mb-4 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-2xl font-bold`}
      }
    }
    .currentPriceBig {
      ${tw`text-2xl font-bold leading-none`}
      .bigText {
        ${tw`text-4xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through`}
    }
    .annualPrice {
      ${tw`text-gray-500 text-lg`}
    }
    .altPrice {
      ${tw`text-gray-500 text-lg hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-4 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
  .descriptionOne {
    ${tw`mt-2 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t pt-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-green-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;
const Chip = tw.span`rounded-full py-1 px-2 ml-2 -mt-1 bg-yellow-1000 text-xxs font-medium text-gray-900`


const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement
    }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container display-linebreak'
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              'data-placement': placement
            })}
          />
        )}
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger'
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

const PlanAction = tw.div`mt-4`;

const DisplayLink = tw(Link)`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-400 px-6 py-4 rounded hover:bg-primary-500 focus:shadow-outline focus:outline-none transition-colors duration-300`;
const DisplayLinkDirect = tw.a`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-400 px-6 py-4 rounded hover:bg-primary-500 focus:shadow-outline focus:outline-none transition-colors duration-300`;
const DisplayLinkCategory = tw.div`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-400 px-6 py-4 rounded hover:bg-primary-500 focus:shadow-outline focus:outline-none transition-colors duration-300`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;


export default ({
  translations, language
}) => {
  const description = translations.pricingModule.description;
  const heading = translations.pricingModule.heading;
  const subheading = translations.pricingModule.subheading;
  let plans = Content.plans;
  if (language === 'es' && translations.pageInfo.locationString.includes('/es/')) {
    plans = Content.esPlans;
  }

  const tabsKeys = Object.keys(translations.pricingModule.tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[2]);


  const renderTabContent = (tabName, index, activeTab, setActiveTab) => {
    const handleClick = () => setActiveTab(tabName);
    const spanText = index === 1 ? translations.pricingModule.yearlyAdmonition : index > 1 ? translations.pricingModule.lifetimeAdmonition : '';

    return (
      <div tw="flex items-start cursor-pointer z-20" onClick={handleClick}>
        {tabName}
        {spanText && (
          <Chip>
            {spanText}
          </Chip>
        )}
      </div>
    );
  };


  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <TabsControl>
            {tabsKeys.map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName || (index === 2 && !plans[activeTab])}
                onClick={() => setActiveTab(tabName)}
              >
                {renderTabContent(tabName, index, activeTab, setActiveTab)}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderContainer>
        <PlansContainer>
          {plans[plans[activeTab] ? activeTab : tabsKeys[2]].map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <h2 className="nameAndFeaturedContainer">
                  {activeTab !== 'Upgrades' && <span className="nameSmaller2" style={{ width: "100%" }}>Gunbot <br />{plan.name}</span>}
                </h2>
                <div className="pricingContainer">
                  <span className={"currentPrice"}>
                    <span className="bigText">{plan.price[0]}</span>
                  </span>
                  {plan.oldPrice && <span className={"oldPrice"}>{plan.oldPrice}</span>}
                  {plan.annualPrice && <span className={"annualPrice"}>{plan.annualPrice}</span>}
                </div>
                <div>
                  {plan.featuredPromo ? <span className="featuredTextPromo">{plan.featuredPromo}</span> : <span className="featuredTextDummy"></span>}
                </div>
                <p className={"description"}>{plan.description}</p>
              </PlanHeader>
              <PlanAction>
                {!isNil(plan?.url?.directLink) ?
                  <DisplayLinkDirect title="Join Gunbot community" href={plan.url.directLink}>
                    {!isNil(plan.buttonText) ? plan.buttonText : translations.pricingModule.primaryButtonText}
                  </DisplayLinkDirect>
                  :
                  <DisplayLink title="Join Gunbot community" to={plan.url}>
                    {!isNil(plan.buttonText) ? plan.buttonText : translations.pricingModule.primaryButtonText}
                  </DisplayLink>
                }
              </PlanAction>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <Tooltip placement="top" trigger="hover" className="display-linebreak" tooltip={feature[1]}>
                      <span className="text">{feature[0]}</span>
                    </Tooltip>
                  </li>
                ))}
                {!isNil(plan.url.details) ?
                  <p className="description display-linebreak" tw={'text-center mt-8 font-bold'}>
                    {!plan.name.includes('black') ? <Link to={plan.url.details} title="Product details">More details about <br />{plan.name.includes('Upgrade') ? plan.name.toLowerCase() : `gunbot ${plan.name.toLowerCase()}`}</Link> : null}
                  </p>
                  : null
                }
              </PlanFeatures>
            </Plan>
          ))}
          <DurationTeaser style={{ maxHeight: '350px' }}>
            {activeTab === tabsKeys[0] && translations.pricingModule.plansDescription}
            {activeTab === tabsKeys[1] && translations.pricingModule.plansDescriptionAnnual}
            {(activeTab === tabsKeys[2] || !plans[activeTab]) && translations.pricingModule.plansDescriptionLifetime}
            {(activeTab === tabsKeys[0] || activeTab === tabsKeys[1] || (activeTab === tabsKeys[2] || !plans[activeTab])) &&
              <DisplayLinkCategory title="Join Gunbot community" onClick={() => setActiveTab(activeTab === tabsKeys[0] ? tabsKeys[1] : activeTab === tabsKeys[1] ? tabsKeys[2] : tabsKeys[0])}>
                {activeTab === tabsKeys[0] && translations.pricingModule.showYearlyPlans}
                {activeTab === tabsKeys[1] && translations.pricingModule.showLifetimePlans}
                {(activeTab === tabsKeys[2] || !plans[activeTab]) && translations.pricingModule.showMonthlyPlans}
              </DisplayLinkCategory>
            }
          </DurationTeaser>
        </PlansContainer>

      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
    </Container>
  );
};
