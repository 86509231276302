import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
import { Link } from 'react-router-dom';

const PrimaryBackgroundContainer = styled.div`
${tw`py-20 bg-primary-900 relative -mt-24`}
  background-color: #1a1a1a;
  background-image:
    radial-gradient(circle at 60% 70%, rgba(15, 35, 55, 0.95) 0%, rgba(25, 45, 65, 0.90) 50%, rgba(10, 30, 50, 0) 100%, transparent 100%),
    radial-gradient(circle at 50% 50%, rgba(20, 40, 60, 0.95) 0%, transparent 100%);
`;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h2`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;
const DisplayLink = tw(Link)`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline bg-primary-400 text-gray-100 shadow-lg hocus:bg-primary-500 hocus:text-gray-200`;

export default ({
  translations
}) => {
  const text = translations.how.text;
  const primaryLinkText = translations.how.primaryLinkText;
  const primaryLinkUrl = translations.how.primaryLinkUrl;
  const primaryLinkTitle = translations.how.primaryLinkTitle;
  const pushDownFooter = true;

  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`} >
      <ContentWithPaddingXl style={{"maxWidth" : "85%"}}>
      <PrimaryBackgroundContainer>
        <Row>
          <TextContainer>
            <Text>{text}</Text>
          </TextContainer>
          <LinksContainer>
              <DisplayLink to={primaryLinkUrl} title={primaryLinkTitle}>{primaryLinkText}</DisplayLink>
          </LinksContainer>
        </Row>
      </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
